<script setup>
import { RouterLink, useRoute } from "vue-router";

const props = defineProps(["link"]);
const route = useRoute();

const isActive = () => {
	return route.name === props.link.name;
};
</script>

<template>
    <RouterLink :to="{ name: link.name }" active-class="menu-bar-active"
                class="flex justify-between items-center w-full bg-custom hover-bg-custom dark-bg-custom px-4 py-3 rounded hover-dark-bg-custom transition duration-700">
        <div class="flex items-center">
            <span class="w-8"><i :class="`fa-solid ${link.icon}`"></i></span>
            <span>{{ $t(link.text) }}</span>
            <span v-if="link.badge"
                  class="bg-red-custom rounded-full px-2 py-1 text-xs ml-2">{{ link.badge }}</span>
        </div>
        <span><i class="fa-solid fa-chevron-right"></i></span>
    </RouterLink>
</template>

<style scoped>
.menu-bar-active {
    border: 1px solid var(--ci-primary-color);
}

.bg-custom {
    background-color: var(--bg-color);
}

.hover-bg-custom:hover {
    background-color: var(--hover-bg-color);
}

.dark-bg-custom {
    background-color: var(--dark-bg-color);
}

.hover-dark-bg-custom:hover {
    background-color: var(--hover-dark-bg-color);
}

.bg-red-custom {
    background-color: var(--red-bg-color);
}

.bg-green-custom {
    background-color: var(--green-bg-color);
}

.opacity-custom {
    opacity: var(--text-opacity);
}

.bg-custom,
.hover-bg-custom:hover,
.dark-bg-custom,
.hover-dark-bg-custom:hover,
.bg-red-custom,
.bg-green-custom,
.opacity-custom {
    transition: background-color 0.3s ease, opacity 0.3s ease;
}
</style>

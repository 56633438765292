<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import { sidebarStore } from "@/Stores/SideBarStore.js";
import MenuBar from "@/Components/UI/MenuBar.vue";
import MenuReturnButton from "@/Components/UI/MenuBar/MenuReturnButton.vue";
import { computed, onMounted } from "vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import { trans } from "laravel-vue-i18n";

const useSidebarStore = sidebarStore();
const menuBarStore = useMenuBarStore();

onMounted(() => {
	const activeLinks = menuBarStore.activeLinks;
	if (activeLinks.length === 0) {
		menuBarStore.setActiveMenu("default");
	}
});

const activeMenu = computed(() => menuBarStore.activeMenu);

const activeMenuLabel = computed(() => {
	switch (activeMenu.value) {
		case "profile":
			return trans("My Profile");
		case "wallet":
			return trans("Balance Management");
		case "history":
			return trans("Betting History");
		default:
			return "";
	}
});
</script>

<template>
    <BaseLayout>
        <div class="md:!pr-[1.5rem] px-2 md:pl-[1.rem] xl:!px-0 xl:w-4/6 mx-auto" :class="{
            'md:!pl-[5.5rem]': !useSidebarStore.sidebarStatus
        }">
            <div class="grid grid-cols-1 md:grid-cols-3 md:gap-4 pt-20">
                <ul class="md:hidden fixed top-[5rem] left-0 w-full z-10" :style="{ backgroundColor: 'var(--dark-bg-color)' }">
                    <MenuReturnButton v-if="activeMenu !== 'default'" :label="activeMenuLabel"/>
                </ul>
                <div class="w-full md:w-1/3 flex justify-center md:justify-start px-4 order-2 md:order-none">
                    <MenuBar/>
                </div>
                <div class="relative col-span-2 order-1 md:order-none px-2">
                    <slot />
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

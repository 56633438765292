import HttpApi from "@/Services/HttpApi.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/Stores/Auth.js";

export default async function useLogoutAccount() {
	const router = useRouter();
	const toast = useToast();
	const authStore = useAuthStore();

	try {
		await HttpApi.post("auth/logout", {});
		await authStore.logout();
		await router.push({ name: "home" });
		toast.success("Has cerrado sesión con éxito");
	} catch (error) {
		if (error.response && error.response.data) {
			const errors = error.response.data;
			for (const [key, value] of Object.entries(errors)) {
				toast.error(`${value}`);
			}
		} else {
			toast.error("Ocurrió un error al intentar cerrar sesión.");
		}
	}
}

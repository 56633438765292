<script setup>
import { useFooterStore } from "@/Stores/Footer.js";
import FooterSocialLinks from "@/Components/Footer/FooterSocialLinks.vue";
import FooterLegal from "@/Components/Footer/FooterLegal.vue";
import FooterBottom from "@/Components/Footer/FooterBottom.vue";
import FooterColumn from "@/Components/Footer/FooterColumn.vue";
import { computed } from "vue";

const year = new Date().getFullYear();
const footerStore = useFooterStore();
const columns = computed(() => footerStore.columns);
</script>

<template>
    <footer class="footer pb-32 md:pb-5 mt-8 footer-color p-4 md:p-8 !border-t !border-solid !border-gray-400">
        <div class="lg:w-5/6 2xl:w-4/6 mx-auto p-2 grid gap-5 grid-cols-1">
            <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-8 justify-between items-start">
                <FooterSocialLinks />
                <FooterColumn v-for="column in columns" :key="column.id" :column="column"/>
            </div>
            <FooterLegal />
            <FooterBottom :year="year" />
        </div>
    </footer>
</template>

<style>
.custom-overflow-hidden {
    overflow: hidden;
}

.filter-color {
    filter: invert(42%) sepia(6%) saturate(0%) hue-rotate(180deg) brightness(92%) contrast(90%);
}

.dark .filter-color {
    filter: invert(61%) sepia(7%) saturate(118%) hue-rotate(184deg) brightness(88%) contrast(85%);
}
</style>

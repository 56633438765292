import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useCategoryStore = defineStore("CategoryStore", {
	state() {
		return {
			categories: [],
			fetching: true,
		};
	},

	actions: {
		async fetch() {
			this.fetching = true;
			try {
				const response = await HttpApi.get("categories");
				this.categories = response.data.categories;
			} catch (error) {
				console.error("Erro ao buscar as categorias:", error);
			} finally {
				this.fetching = false;
			}
		},
	},
});

<script setup>
import { ref } from "vue";

const props = defineProps({
	game: Object,
	toggleFavorite: Function,
	toggleLike: Function,
});

const emit = defineEmits([
	"toggleFavorite",
	"toggleLike",
	"toggleModeMovie",
	"togglefullscreen",
]);

const modeMovie = ref(false);

function toggleModeMovie() {
	modeMovie.value = !modeMovie.value;
	emit("toggleModeMovie", modeMovie.value);
}

function togglefullscreen() {
	emit("togglefullscreen");
}
</script>

<template>
    <div class="flex justify-between items-center w-full px-3 py-2 bg-gray-300/20 dark:bg-gray-800 game-footer">
        <div class="flex items-center gap-3 text-gray-500">
            <button @click.prevent="toggleFavorite" :class="{ 'text-yellow-400': game.hasFavorite }">
                <i class="fa-solid fa-star mr-2"></i> {{ game.totalFavorites }}
            </button>
            <button @click.prevent="toggleLike" :class="{ 'text-red-500': game.hasLike }">
                <i class="fa-solid fa-heart mr-2"></i> {{ game.totalLikes }}
            </button>
            <button><i class="fa-regular fa-eyes mr-2"></i> {{ game.views }}</button>
        </div>
        <div class="text-gray-500 flex items-center gap-3">
            <div>
                <button data-tooltip-target="tooltip-mode-movie" type="button" @click.prevent="toggleModeMovie">
                    <i class="fa-solid fa-window-restore"></i>
                </button>
                <div id="tooltip-mode-movie" role="tooltip"
                     class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Modo Filme
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
            <div>
                <button data-tooltip-target="tooltip-mode-expand" type="button" @click.prevent="togglefullscreen">
                    <i class="fa-sharp fa-solid fa-expand"></i>
                </button>
                <div id="tooltip-mode-expand" role="tooltip"
                     class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Modo Fullscreen
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.game-footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
</style>

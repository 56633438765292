<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import HttpApi from "@/Services/HttpApi.js";
import SearchForm from "@/Components/Cassino/SearchForm.vue";
import GameList from "@/Components/Cassino/GameList.vue";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import HeaderComponent from "@/Components/UI/HeaderComponent.vue";
import EmptyDataMessage from "@/Components/Cassino/EmptyDataMessage.vue";
import GameCardSkeleton from "@/Components/Cassino/GameCardSkeleton.vue";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";

const route = useRoute();
const isLoading = ref(false);
const games = ref(null);
const searchTerm = ref("");
const isEmpty = ref(false);
const loadingGames = ref(false);

const searchGames = async () => {
	if (searchTerm.value.length > 2) {
		await getGameData(1, false);
	} else {
		await getGameData(1, false);
	}
};

const getGameData = async (page = 1, loading = true) => {
	isLoading.value = loading;
	loadingGames.value = true;

	try {
		const response = await HttpApi.get(
			`/casinos/games?page=${page}&searchTerm=${searchTerm.value}&category=${route.params.category}&provider=${route.params.provider}`,
		);

		games.value = {
			...response.data.games,
			data: response.data.games.data.map((game) => ({
				id: game.id,
				cover: game.cover || "/default-cover.jpg",
				game_code: game.game_id || "",
				distribution: game.distribution || "",
			})),
		};

		isEmpty.value = games.value.total === 0;
	} catch (error) {
		console.error(error);
	} finally {
		isLoading.value = false;
		loadingGames.value = false;
	}
};

watch(
	() => route.params.provider,
	() => getGameData(1, true),
);
watch(
	() => route.params.category,
	() => getGameData(1, true),
);

onMounted(() => {
	getGameData(1, false);
});
</script>

<template>
    <BaseLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>Carregando os jogos</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading" class="md:w-4/6 2xl:w-4/6 mx-auto">
            <div class="px-4 pb-5 pt-12">
                <HeaderComponent>
                    <template #header>
                        {{ $t("List of") }}
                        <span
                            class="text-2xl font-semibold me-2 px-2.5 py-0.5 rounded ms-2 text-gray-900 bg-[var(--ci-primary-color)]"
                            >{{ $t("Games") }}</span
                        >
                    </template>
                    <p class="text-2xl flex items-center justify-center">
                        {{ $t("Total") }}
                        <strong>({{ games?.total ?? 0 }})</strong>
                    </p>
                </HeaderComponent>

                <SearchForm :searchTerm="searchTerm" @input="searchGames" />

                <template v-if="loadingGames">
                    <div class="flex flex-col">
                        <div class="grid grid-cols-2 md:grid-cols-6 mb-3 gap-4">
                            <GameCardSkeleton v-for="n in 10" />
                        </div>
                    </div>
                </template>

                <template v-else>
                    <GameList
                        v-if="!isEmpty && games"
                        :games="games.data"
                    />

                    <EmptyDataMessage v-else />
                </template>
            </div>
        </div>
    </BaseLayout>
</template>

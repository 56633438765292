<script setup>
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { trans } from "laravel-vue-i18n";
import HttpApi from "@/Services/HttpApi.js";
import Table from "@/Components/Profile/LoginHistory/Table.vue";

const loginHistories = ref([]);
const isLoading = ref(false);
const toast = useToast();

const fetchLoginHistory = async () => {
	isLoading.value = true;
	try {
		const response = await HttpApi.get("profile/login-history");
		loginHistories.value = response.data;

		if (loginHistories.value.length === 0) {
			throw new Error(trans("No login history found."));
		}
	} catch (error) {
		toast.error(error.message);
	} finally {
		isLoading.value = false;
	}
};

onMounted(async () => {
	await fetchLoginHistory();
});
</script>

<template>
    <BaseProfileLayout>
        <div class="flex flex-col gap-4">
            <div class="profile-section-container">
                <h1
                    class="font-bold text-sm"
                    style="color: var(--title-color); opacity: 0.9"
                >
                    {{ $t("Histórico de Login:").toUpperCase() }}
                </h1>
            </div>

            <div v-if="isLoading" class="profile-section-container">
                {{ $t("Loading...") }}
            </div>

            <div v-if="!isLoading">
                <Table :loginHistories="loginHistories"/>
            </div>
        </div>
    </BaseProfileLayout>
</template>

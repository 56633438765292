<script setup>
import ShareLinkButton from "@/Components/Profile/Affilliate/ShareLinkButton.vue";
import CopyLinkButton from "@/Components/Profile/Affilliate/CopyLinkButton.vue";
import { ref } from "vue";

const link = ref("https://flamix.com?ref=71094f54986d");
</script>

<template>
    <div class="profile-section-container">
        <h1 class="flex gap-2 justify-center items-center text-center w-full text-xl font-bold"
            style="color: var(--ci-primary-color);">
            <svg class="w-5" viewBox="0 0 512 512"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M276.1 118.3C282.7 119.5 292.7 121.5 297.1 122.7C307.8 125.5 314.2 136.5 311.3 147.1C308.5 157.8 297.5 164.2 286.9 161.3C283 160.3 269.5 157.7 265.1 156.1C252.9 155.1 242.1 156.7 236.5 159.6C230.2 162.4 228.7 165.7 228.3 167.7C227.7 171.1 228.3 172.3 228.5 172.7C228.7 173.2 229.5 174.4 232.1 176.2C238.2 180.4 247.8 183.4 261.1 187.7L262.8 187.9C274.9 191.6 291.1 196.4 303.2 205.3C309.9 210.1 316.2 216.7 320.1 225.7C324.1 234.8 324.9 244.8 323.1 255.2C319.8 274.2 307.2 287.2 291.4 293.9C286.6 295.1 281.4 297.5 276.1 298.5V304C276.1 315.1 267.1 324.1 255.1 324.1C244.9 324.1 235.9 315.1 235.9 304V297.6C226.4 295.4 213.1 291.2 206.1 288.5C204.4 287.9 202.9 287.4 201.7 286.1C191.2 283.5 185.5 272.2 189 261.7C192.5 251.2 203.8 245.5 214.3 249C216.3 249.7 218.5 250.4 220.7 251.2C230.2 254.4 240.9 258 246.9 259C259.7 261 269.6 259.7 275.7 257.1C281.2 254.8 283.1 251.8 283.7 248.3C284.4 244.3 283.8 242.5 283.4 241.7C283.1 240.8 282.2 239.4 279.7 237.6C273.8 233.3 264.4 230.2 250.4 225.9L248.2 225.3C236.5 221.8 221.2 217.2 209.6 209.3C203 204.8 196.5 198.6 192.3 189.8C188.1 180.9 187.1 171 188.9 160.8C192.1 142.5 205.1 129.9 220 123.1C224.1 120.9 230.3 119.2 235.9 118V112C235.9 100.9 244.9 91.9 256 91.9C267.1 91.9 276.1 100.9 276.1 112L276.1 118.3zM136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H63.98C83.43 377.9 108 399.7 136.2 416H136.2z"
                    fill="currentColor"></path>
                <path
                    d="M48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 322.9 370.9 416 256 416C141.1 416 48 322.9 48 208zM276.1 112C276.1 100.9 267.1 91.9 255.1 91.9C244.9 91.9 235.9 100.9 235.9 112V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3L276.1 112z"
                    fill="currentColor" opacity="0.4"></path>
            </svg>
            <span>
                        {{ $t('Indique um amigo e ganhe dinheiro') }}
                    </span>
        </h1>

        <h2 class="text-center text-white mt-1 w-full font-md">
            {{ $t('Ganhe 5,00 R$ de saldo real por cada amigo que você indicar para a RocketGaming.') }}
        </h2>

        <p class="yellow-text text-center w-full mt-1">
            Para efetivar seu bônus, o primeiro depósito de seu amigo deve ser no mínimo de
            20,00 R$
        </p>

        <div class="flex flex-col lg:grid lg:grid-cols-6 items-center flex-wrap mt-2 gap-4">
            <div class="w-full md:col-span-4">
                <input
                    type="text"
                    class="w-full bg-transparent border border-gray-300 !border-[var(--ci-primary-color)]
                            rounded-md appearance-none"
                    v-model="link"
                    required
                />
            </div>

            <div class="flex gap-4">
                <CopyLinkButton :link="link" />
                <ShareLinkButton />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>
import { ref } from "vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SearchForm from "@/Components/Cassino/SearchForm.vue";
import SearchMessage from "@/Components/Cassino/SearchMessage.vue";
import GameList from "@/Components/Cassino/GameList.vue";
import CustomPagination from "@/Components/UI/CustomPagination.vue";
import HttpApi from "@/Services/HttpApi.js";

const isLoading = ref(false);
const searchTerm = ref("");
const gamesSlotegrator = ref(null);

const searchGames = async () => {
	if (searchTerm.value.length > 2) {
		await getData();
	}
};

const getData = async (page = 1) => {
	try {
		const response = await HttpApi.get(
			`/casinosslotegrator?page=${page}&searchTerm=${searchTerm.value}`,
		);
		gamesSlotegrator.value = response.data.gamesSlotegrator;
	} catch (error) {
		console.error(error);
	}
};
</script>

<template>
    <BaseLayout>
        <div class="my-auto">
            <div class="px-4 py-5">
                <SearchForm :searchTerm="searchTerm" @input="searchGames" />
                <SearchMessage v-if="!gamesSlotegrator" />
                <GameList v-else :games="gamesSlotegrator.data" />
                <CustomPagination v-if="gamesSlotegrator" :data="gamesSlotegrator" @pagination-change-page="getData" />
            </div>
        </div>
    </BaseLayout>
</template>

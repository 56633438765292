import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useBottomNavigationLinkStore = defineStore(
	"bottomNavigationLink",
	{
		state() {
			return {
				links: [
					{
						id: 0,
						text: "",
						url: "",
						icon: "",
						text_color: "",
						is_live_event: false,
					},
				],
			};
		},

		actions: {
			async fetch() {
				try {
					const response = await HttpApi.get("/layout/bottom-navigation-links");
					this.links = response.data;
				} catch (error) {}
			},
		},

		getters: {},
	},
);

import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useDepositCardStore = defineStore("depositCard", {
	state() {
		return {
			depositCards: [{}],
		};
	},

	actions: {
		async fetchDepositCards() {
			try {
				const response = await HttpApi.get("deposits/cards");
				this.depositCards = response.data;
			} catch (error) {
				console.error("Erro ao buscar os cards de depósito:", error);
			}
		},
	},
});

<script setup>
import { useHighlightedButtonStore } from "@/Stores/HighlightedButtonStore.js";
import { computed } from "vue";

const highlightedButtonStore = useHighlightedButtonStore();

const buttons = computed(() => highlightedButtonStore.buttons);
</script>

<template>
    <div class="flex flex-col gap-y-4 border-b-[1px] border-b-slate-50 border-opacity-10 p-4">
        <template v-for="button in buttons">
            <button @click="button.action"
                    class="invite-friend-active flex w-full items-center justify-center rounded hover:opacity-[0.7]">
                <span class="flex min-h-[50px] min-w-[50px] items-center justify-center rounded-[4px] bg-[#111212]">
                    <span class="text-2xl">
                        {{ button.emoji }}
                    </span>
                </span>
            </button>
        </template>
    </div>
</template>

<style scoped>
.sidebar-highlight {
    margin-bottom: 20px;
}

.btn-bonus {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlight-title {
    font-weight: bold;
}

.emoji {
    font-size: 20px;
    margin-left: 10px;
}

.lightEffect {
    background: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: background 0.15s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    width: 100%;
    z-index: 1;
}

.btn-width {
    width: 220px;
}

@media (max-width: 640px) {
    .btn-width {
        width: 350px;
    }
}
</style>

<script setup>
import { ref } from "vue";

const emit = defineEmits(["selectFilter"]);

const filters = [
	{ key: "today", label: "Only today" },
	{ key: "yesterday", label: "Only yesterday" },
	{ key: "7days", label: "Last 7 days" },
	{ key: "30days", label: "Last 30 days" },
	{ key: "90days", label: "Last 90 days" },
	{ key: "total", label: "Total period" },
];

const selectedFilter = ref("today");

const selectFilter = (filterKey) => {
	selectedFilter.value = filterKey;
	emit("selectFilter", filterKey);
};
</script>

<template>
    <div class="flex flex-wrap gap-2 mb-4">
        <button v-for="filter in filters" :key="filter.key" :class="[
                        'filter-button',
                        selectedFilter === filter.key ? 'selected' : ''
                    ]" @click="selectFilter(filter.key)">
            {{ $t(filter.label) }}
        </button>
    </div>
</template>
<style scoped>
.filter-button {
    font-family: inherit;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    line-height: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--border-radius);
    border: none;
    cursor: pointer;
    flex: 1;
    background-color: var(--ci-primary-color);
    color: var(--title-color);
    box-shadow: var(--tw-shadow);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.filter-button:hover {
    background-color: var(--hover-bg-color);
    transform: scale(1.02);
}

.filter-button.selected {
    background-color: var(--green-bg-color);
    color: var(--vc-clr-white);
    box-shadow: 0px 0px 10px var(--green-bg-color);
}

@media (max-width: 768px) {
    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .filter-button {
        flex: 1 0 calc(50% - 0.5rem);
    }

    .filter-button {
        flex: 1 0 calc(33.333% - 0.5rem) !important;
    }
}
</style>

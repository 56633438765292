<script setup>
import { ref } from "vue";

const notifications = ref([]);

const fetchNotifications = () => {
	//...
};
</script>

<template>

    <div class="mt-4" v-if="notifications.length < 1">
        <div class="alert-warning text-sm bg-gray-800 text-center p-4 rounded-lg">
            {{ $t('Você ainda não possui notificações!') }}
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";
import "/resources/css/games.css";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const props = defineProps({
	provider: {
		type: Object,
		required: true,
	},
	index: {
		type: Number,
		default: 0,
	},
});

const ckCarousel = ref(null);
const isLoading = ref(false);
const reactiveBreakpoints = useBreakpoints({
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	"2xl": 1536,
});
const isMobile = reactiveBreakpoints.smaller("md");

// Configurações do carousel
const settingsGames = {
	itemsToShow: 3,
	snapAlign: "start",
};

const breakpointsGames = {
	700: {
		itemsToShow: 3,
		snapAlign: "center",
	},
	1024: {
		itemsToShow: 6,
		snapAlign: "start",
	},
};

const maxItemsToShow = computed(() => {
	if (reactiveBreakpoints.isGreaterOrEqual("lg")) return 6;
	if (reactiveBreakpoints.isGreaterOrEqual("md")) return 3;
	return 3;
});

const showNavigation = computed(() => {
	if (isLoading.value) return false;
	return (
		props.provider.games && props.provider.games.length > maxItemsToShow.value
	);
});

const currentSlide = ref(props.index);

const totalSlides = computed(() => {
	return props.provider.games ? props.provider.games.length : 0;
});

const canGoPrev = computed(() => currentSlide.value > 0);

const canGoNext = computed(
	() => currentSlide.value + maxItemsToShow.value < totalSlides.value,
);

const onCarouselInit = (event) => {
	if (event && typeof event.currentSlideIndex === "number") {
		currentSlide.value = event.currentSlideIndex;
	} else {
		currentSlide.value = 0;
	}
};

const onSlideEnd = (event) => {
	if (event && typeof event.currentSlideIndex === "number") {
		currentSlide.value = event.currentSlideIndex;
	} else {
		currentSlide.value = 0;
	}
};

watch(maxItemsToShow, () => {
	if (currentSlide.value + maxItemsToShow.value > totalSlides.value) {
		currentSlide.value = Math.max(totalSlides.value - maxItemsToShow.value, 0);
		if (ckCarousel.value) {
			ckCarousel.value.goTo(currentSlide.value);
		}
	}
});
</script>

<template>
    <div class="game-list flex flex-col mt-3 relative">
        <div class="mb-2 flex w-full items-center justify-between">
            <div class="flex gap-6 items-center">
                <h2 class="heading-style">
                    {{ $t(provider.name) }}
                </h2>
                <div v-if="!isMobile && showNavigation" class="flex items-center">
                    <button 
                        v-if="canGoPrev" 
                        @click.prevent="ckCarousel.prev()" 
                        class="item-game px-2 py-1 rounded mr-2 bg-[var(--ci-primary-color)] text-white text-sm"
                    >
                        <i class="fa-solid fa-angle-left text-xs"></i>
                    </button>
                    <button 
                        v-if="canGoNext" 
                        @click.prevent="ckCarousel.next()" 
                        class="item-game px-2 py-1 rounded bg-[var(--ci-primary-color)] text-white text-sm"
                    >
                        <i class="fa-solid fa-angle-right text-xs"></i>
                    </button>
                </div>
            </div>
            <div class="flex">
                <RouterLink :to="{ name: 'casinosAll', params: { provider: provider.id, category: 'all' } }"
                    class="inline-flex items-center justify-end bg-[var(--ci-primary-color)] rounded-full text-xs font-medium ml-auto px-2 py-0.5 gap-1 no-underline"
                    style="line-height: 1rem;">
                    <span style="font-size: 0.6rem; font-weight: 600; line-height: 1rem;">
                        {{ $t('See all') }}
                    </span>
                </RouterLink>
            </div>
        </div>

        <Carousel 
            ref="ckCarousel" 
            v-bind="settingsGames" 
            :breakpoints="breakpointsGames" 
            @init="onCarouselInit" 
            @slide-end="onSlideEnd"
        >
            <Slide v-if="isLoading" v-for="(i, iloading) in 10" :key="iloading">
                <div role="status"
                    class="w-full flex items-center justify-center h-48 mr-6 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700 text-4xl">
                    <i class="fa-duotone fa-gamepad-modern"></i>
                </div>
            </Slide>
            <Slide v-if="provider.games && !isLoading" v-for="(game, providerId) in provider.games" :key="providerId">
                <CassinoGameCard 
                    :index="providerId" 
                    :title="game.game_name" 
                    :cover="game.cover"
                    :gamecode="game.game_code" 
                    :type="game.distribution" 
                    :game="game" 
                />
            </Slide>
        </Carousel>
    </div>
</template>
<script setup>
import { useModalStore } from "@/Stores/ModalStore.js";

const props = defineProps(["showMobile", "isFull"]);

const modalStore = useModalStore();

const openDepositModal = () => {
	modalStore.openDepositModal();
};
</script>

<template>
    <button @click.prevent="openDepositModal" type="button"
            :class="[showMobile === false ? 'hidden md:block' : '', isFull ? 'w-full' : '']"
            class="ui-button-blue rounded shadow-[0px_0px_8px_var(--ci-primary-color)] !text-center">
        <span>
            {{ $t('Deposit') }}
        </span>
    </button>
</template>

<style scoped>
.ui-button-blue {
    color: white;
    padding: 0.02rem 1rem;
    border: none;
    cursor: pointer;
    line-height: 2rem;
    transition: background-color 0.3s ease;
}
</style>

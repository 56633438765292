import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useFooterLinkStore = defineStore("FooterLinkStore", {
	state() {
		return {
			links: [],
			fetching: true,
		};
	},

	actions: {
		async fetch() {
			this.fetching = true;
			try {
				await HttpApi.get("/layout/sidebar-footer-links").then((response) => {
					const data = response.data;
					this.links = [...data];
				});
			} catch (error) {
				console.error("Erro ao buscar os links do rodapé:", error);
			} finally {
				this.fetching = false;
			}
		},
	},
});

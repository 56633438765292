<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";

const isMobile = ref(false);
const menuBarStore = useMenuBarStore();
const isLoading = ref(true);
const authStore = useAuthStore();
const profile = ref();

const checkIfMobile = () => {
	isMobile.value = window.innerWidth <= 768;
};

const fetchUserData = async () => {
	try {
		const response = await HttpApi("profile");
		profile.value = response.data;
	} catch (error) {
		console.error("Erro ao carregar os dados do usuário:", error);
		if (error.response && error.response.status === 401) {
			await authStore.logout();
		}
	} finally {
		isLoading.value = false;
	}
};

onMounted(() => {
	menuBarStore.setActiveMenu("profile");
	fetchUserData();
	checkIfMobile();
	window.addEventListener("resize", checkIfMobile);
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", checkIfMobile);
});
</script>

<template>
    <BaseProfileLayout>
        <div>
            <div class="mb-4 profile-section-container">
                <h1 class="text-sm font-bold" style="color: var(--title-color)">
                    Detalhes da Conta
                </h1>
            </div>

            <div class="profile-section-container">
                <div v-if="isLoading">
                    <p class="mb-6 text-lg" style="color: var(--text-color)">
                        Carregando informações...
                    </p>
                </div>

                <div v-else class="lg:max-w-[62rem]">
                    <section class="mb-6">
                        <div class="flex items-center gap-2">
                            <h2
                                class="text-lg font-semibold"
                                style="color: var(--title-color)"
                            >
                                E-mail
                            </h2>
                            <span
                                :class="
                                    profile.user.email_verified_at
                                        ? 'bg-green-500'
                                        : 'bg-red-500'
                                "
                                class="px-3 py-1 text-xs font-semibold text-white rounded-full"
                            >
                                {{
                                    profile.user.email_verified_at
                                        ? "Verificado"
                                        : "Não Verificado"
                                }}
                            </span>
                        </div>
                        <p class="mt-3" style="color: var(--text-color)">
                            {{ profile.user.email }}
                        </p>
                        <hr
                            class="my-4 border-t"
                            style="border-color: var(--sub-text-color)"
                        />
                    </section>

                    <section class="mb-6">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <h2
                                    class="text-lg font-semibold"
                                    style="color: var(--title-color)"
                                >
                                    Celular
                                </h2>
                                <span
                                    :class="
                                        profile.user.phone
                                            ? 'bg-green-500'
                                            : 'bg-red-500'
                                    "
                                    class="px-3 py-1 text-xs font-semibold text-white rounded-full"
                                >
                                    {{
                                        profile.user.phone
                                            ? "Verificado"
                                            : "Não Verificado"
                                    }}
                                </span>
                            </div>
                            <button style="color: var(--text-color)">
                                Editar
                            </button>
                        </div>
                        <p class="mt-3" style="color: var(--text-color)">
                            {{ profile.user.phone || "Número não fornecido" }}
                            <br />
                            <button
                                v-if="!profile.user.phone"
                                class="mt-3"
                                style="color: var(--sub-text-color)"
                            >
                                Clique aqui para verificar o número do seu
                                celular
                            </button>
                        </p>
                        <hr
                            class="my-4 border-t"
                            style="border-color: var(--sub-text-color)"
                        />
                    </section>

                    <section class="mb-6">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <h2
                                    class="text-lg font-semibold"
                                    style="color: var(--title-color)"
                                >
                                    Endereço
                                </h2>
                                <span
                                    class="px-3 py-1 text-xs font-semibold text-white bg-red-500 rounded-full"
                                >
                                    Incompleto
                                </span>
                            </div>
                            <button style="color: var(--text-color)">
                                Editar
                            </button>
                        </div>
                        <p class="mt-3 mb-6" style="color: var(--text-color)">
                            {{
                                profile.user.address ||
                                "Endereço não fornecido ou incompleto."
                            }}
                            <br />
                            <button
                                class="mt-3"
                                style="color: var(--sub-text-color)"
                            >
                                Clique aqui para atualizar.
                            </button>
                        </p>
                        <hr
                            class="my-4 border-t"
                            style="border-color: var(--sub-text-color)"
                        />
                    </section>

                    <section class="mb-6">
                        <div class="flex items-center justify-between">
                            <div class="flex items-center gap-2">
                                <h2
                                    class="text-lg font-semibold"
                                    style="color: var(--title-color)"
                                >
                                    Documento
                                </h2>
                                <span
                                    :class="
                                        profile.user.cpf
                                            ? 'bg-green-500'
                                            : 'bg-red-500'
                                    "
                                    class="px-3 py-1 text-xs font-semibold text-white rounded-full"
                                >
                                    {{
                                        profile.user.cpf
                                            ? "KYC Completo"
                                            : "KYC Pendente"
                                    }}
                                </span>
                            </div>
                            <button style="color: var(--text-color)">
                                Ver dados
                            </button>
                        </div>
                        <p class="mt-3 mb-3" style="color: var(--text-color)">
                            {{ profile.user.cpf || "Documento não fornecido" }}
                            <br />
                            <button
                                v-if="!profile.user.cpf"
                                style="color: var(--sub-text-color)"
                            >
                                Verifique sua identidade (KYC)
                            </button>
                        </p>
                        <hr
                            class="my-4 border-t"
                            style="border-color: var(--sub-text-color)"
                        />
                    </section>
                </div>
            </div>
        </div>
    </BaseProfileLayout>
</template>

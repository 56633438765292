<script setup>
import { ref } from "vue";
import { trans } from "laravel-vue-i18n";

const tabs = ref([
	{ name: trans("Depósitos"), active: true },
	{ name: trans("Saques"), active: false },
	{ name: trans("Cupons"), active: false },
	{ name: trans("Bônus"), active: false },
	{ name: trans("Clube Vip"), active: false },
]);

const activeTab = defineModel();

const setActiveTab = (index) => {
	tabs.value.forEach((tab, i) => {
		tab.active = i === index;
	});

	activeTab.value = index;
};
</script>

<template>
    <div class="flex gap-4 overflow-x-auto border-b border-gray-600 pb-2">
        <template v-for="(tab, index) in tabs" :key="index">
            <div
                @click="setActiveTab(index)"
                :class="{
                            'text-[var(--ci-primary-color)] font-extrabold underline decoration-[var(--ci-primary-color)]': tab.active,
                            'text-gray-500': !tab.active,
                            'cursor-pointer': true,
                        }"
                class="text-sm font-bold"
            >
                {{ tab.name }}
            </div>
        </template>
    </div>
</template>

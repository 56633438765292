<script setup>
import DateFilters from "@/Components/Profile/DateFilters.vue";

const activePeriod = (period) => {
	console.log(period);
};
</script>

<template>
    <div class="profile-section-container">
        <DateFilters @selectFilter="activePeriod" />
    </div>
</template>

<style scoped>
.button-period {
    border-radius:.25rem;
    cursor:pointer;
    font-weight:600;
    opacity:.7;
    padding:.125rem .375rem;
    position:relative
}
</style>

<script setup>
const props = defineProps(["title", "options"]);

const selectedProviders = defineModel("selectedProviders");
const emit = defineEmits(["searchGames"]);

const searchGames = () => {
	emit("searchGames");
};
</script>

<template>
    <div class="dropdown">
        <button class="dropdown-button">
            {{ $t(title) }}
        </button>
        <div class="dropdown-content">
            <label
                v-for="provider in options"
                :key="provider"
            >
                <input
                    type="checkbox"
                    :value="provider"
                    v-model="selectedProviders"
                    @change="searchGames"
                />
                {{ provider }}
            </label>
        </div>
    </div>
</template>

<style scoped>
.dropdown {
    position: relative;
}

.dropdown-button {
    background-color: var(--ci-primary-color);
    padding: 0.3rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--background-color);
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0.3rem;
    z-index: 10;
    font-size: 0.875rem;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.text-center span {
    color: var(--text-color);
}
</style>

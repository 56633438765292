<script setup>
import { ref, onMounted } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import DateFilters from "@/Components/Profile/DateFilters.vue";

const menuBarStore = useMenuBarStore();
const isLoading = ref(true);
const selectedFilter = ref("today");

onMounted(() => {
	menuBarStore.setActiveMenu("history");
	setTimeout(() => (isLoading.value = false), 1500);
});

const selectFilter = (filterKey) => {
	selectedFilter.value = filterKey;
};
</script>

<template>
    <BaseProfileLayout>
        <div>
            <div class="mb-4 profile-section-container">
                <h1 class="text-sm font-bold" style="color: var(--title-color);">
                    {{ $t("Sports Betting History").toUpperCase() }}
                </h1>
            </div>

            <div class="profile-section-container">
                <DateFilters @selectFilter="selectFilter" />

                <div v-if="isLoading">
                    <p class="mb-6 mt-2 text-lg text-center" style="color: var(--text-color);">
                        {{ $t('Loading') }}...
                    </p>
                </div>

                <div v-else>
                    <div v-if="selectedFilter"
                        class="flex items-center justify-center h-32 profile-section-container rounded-md">
                        <p class="text-[var(--title-color)] text-sm alert-warning">
                            {{ $t("There is no information to display") }}.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </BaseProfileLayout>
</template>

<style scoped>
.profile-section-container {
    background-color: var(--bg-color-secondary);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 16px;
}
</style>

import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useTextBannerStore = defineStore("TextBannerStore", {
	state() {
		return {
			banner: {
				text: "",
				link: "",
				background_color: "",
				text_color: "",
				has_button: "",
				button_text: "",
				button_link: "",
				button_color: "",
				button_text_color: "",
			},
			showTextBanner: true,
		};
	},

	actions: {
		async fetch() {
			await HttpApi.get("/layout/text-banners")
				.then((response) => {
					const data = response.data;
					this.banner.text = data.text;
					this.banner.link = data.link;
					this.banner.background_color = data.background_color;
					this.banner.text_color = data.text_color;
					this.banner.has_button = data.has_button;
					this.banner.button_text = data.button_text;
					this.banner.button_link = data.button_link;
					this.banner.button_color = data.button_color;
					this.banner.button_text_color = data.button_text_color;

					this.showTextBanner = true;

					return this.banner;
				})
				.catch((error) => {
					this.showTextBanner = false;
				});
		},
		close() {
			this.showTextBanner = false;
		},
	},
});

<script setup>
import { useFooterStore } from "@/Stores/Footer.js";
import LanguageSelector from "@/Components/Footer/LanguageSelector.vue";

const footerText = useFooterStore().footerText;
const logoTM = useFooterStore().logoTM;
const languages = useFooterStore().languages;
const selectedLanguage = useFooterStore().selectedLanguage;
defineProps(["year"]);
</script>

<template>
    <div class="text-center grid gap-5 grid-cols-1">
        <div class="flex justify-center items-center">
            <img :src="logoTM.src" :alt="logoTM.alt" :style="{ width: logoTM.width }"/>
        </div>
        <p class="text-[12px]">¡Hecho con ❤️ para todo el mundo!</p>
        <div class="grid gap-5 grid-cols-1">
            <div class="flex items-center justify-end">
                <p class="text-[12px] md:text-sm text-gray-600 dark:text-gray-400 mr-4">
                    {{ footerText }}
                </p>
                <img :src="`/assets/images/icons/bcg.png`" alt="bcg" width="170"/>
            </div>
            <div class="flex flex-col items-center gap-4">
                <div class="flex gap-1">
                    <img :src="`/assets/images/icons/gt_logo.png`" alt="gt" width="70" class="mr-4"/>
                    <img :src="`/assets/images/icons/juega.png`" alt="juega" width="200"/>
                    <img :src="`/assets/images/icons/BeGambleAware.png`" alt="juega" width="200"/>
                </div>
                <div class="mt-2 flex items-center text-gray-600 dark:text-gray-400 text-sm flex-wrap">
                    <span class="font-bold">Suporte:</span><span class="ml-1 font-bold">suportesuporte@flamix.casino</span>
                    <span class="mx-2 font-bold">|</span>
                    <span class="font-bold">Jurídico:</span><span class="ml-1 font-bold">juridico@flamix.casino</span>
                    <span class="mx-2 font-bold">|</span>
                    <span class="font-bold">Parceiros:</span><span class="ml-1 font-bold">parceiros@flamix.casino</span>
                </div>
                <LanguageSelector :languages="languages" :selectedLanguage="selectedLanguage"/>
            </div>
            <div class="flex justify-center items-center">
                <p class="text-[12px]">© {{ year }} Rocket Gaming - Todos os direitos reservados.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import { computed } from "vue";
import MenuButton from "@/Components/UI/MenuBar/MenuButton.vue";
import LogoutButton from "@/Components/UI/MenuBar/LogoutButton.vue";
import LinkButton from "@/Components/UI/MenuBar/LinkButton.vue";
import MenuReturnButton from "@/Components/UI/MenuBar/MenuReturnButton.vue";
import { trans } from "laravel-vue-i18n";

const menuBarStore = useMenuBarStore();
const links = computed(() => menuBarStore.activeLinks);
const activeMenu = computed(() => menuBarStore.activeMenu);

const activeMenuLabel = computed(() => {
	switch (activeMenu.value) {
		case "profile":
			return trans("My Profile");
		case "wallet":
			return trans("Balance Management");
		case "history":
			return trans("Betting History");
		default:
			return "";
	}
});

const isWideScreen = computed(() => {
	if (typeof window !== "undefined") {
		return window.innerWidth > 768;
	}
	return false;
});
</script>

<template>
    <div class="w-full rounded">
        <ul>
            <MenuReturnButton v-if="activeMenu !== 'default' && isWideScreen" :label="activeMenuLabel"/>
            <li v-for="link in links" :key="link.name" class="w-full mb-2">
                <template v-if="link.route">
                    <LinkButton :link="link"/>
                </template>

                <template v-else>
                    <MenuButton :link="link" :action="link.action" :active="link.active"/>
                </template>
            </li>

            <LogoutButton v-if="activeMenu === 'default'"/>
        </ul>
    </div>
</template>

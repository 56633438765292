<script setup>
import { ref, onMounted } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";

const isLoading = ref(true);
const menuBarStore = useMenuBarStore();
const authStore = useAuthStore();
const profile = ref();
const selectedOption = ref(null);
const selectedPeriod = ref("");

const fetchUserData = async () => {
	try {
		const response = await HttpApi("profile");
		profile.value = response.data;
	} catch (error) {
		console.error("Error fetching user data:", error);
		if (error.response && error.response.status === 401) {
			await authStore.logout();
		}
	} finally {
		isLoading.value = false;
	}
};

onMounted(() => {
	menuBarStore.setActiveMenu("self-exclusion");
	fetchUserData();
});
</script>

<template>
    <BaseProfileLayout>
        <div>
            <div class="mb-4 profile-section-container">
                <h1 class="text-sm font-bold" style="color: var(--title-color)">
                    {{ $t("Self-exclusion") }}
                </h1>
            </div>

            <div class="profile-section-container">
                <div v-if="isLoading">
                    <p class="mb-6 text-lg" style="color: var(--text-color)">
                        {{ $t("Loading information...") }}
                    </p>
                </div>

                <div v-else>
                    <div class="mb-6">
                        <p class="description">
                            {{ $t("When using the self-exclusion feature, you will not be able to access your account or perform any activities for the period specified by you (3 months to 5 years) or permanently, but you will still be able to withdraw from your account.") }}
                        </p>
                    </div>

                    <section class="mb-6">
                        <div class="flex items-center mb-4">
                            <h2
                                class="text-sm font-semibold mr-4"
                                style="color: var(--title-color)"
                            >
                                {{ $t("Set self-exclusion:") }}
                            </h2>
                            <div class="radio-buttons flex items-center">
                                <label class="radio-label">
                                    <input
                                        class="radio-input"
                                        type="radio"
                                        value="0"
                                        v-model="selectedOption"
                                    />
                                    <span>{{ $t("No") }}</span>
                                </label>
                                <label class="radio-label">
                                    <input
                                        class="radio-input"
                                        type="radio"
                                        value="1"
                                        v-model="selectedOption"
                                    />
                                    <span>{{ $t("Yes") }}</span>
                                </label>
                            </div>
                        </div>

                        <div v-if="selectedOption === '1'" class="mt-4">
                            <label
                                for="fieldType"
                                class="text-sm font-semibold mb-2 block"
                                style="color: var(--title-color)"
                            >
                                {{ $t("Period:") }}
                            </label>
                            <select
                                id="fieldType"
                                v-model="selectedPeriod"
                                class="select"
                                style="width: 100%; max-width: 400px"
                            >
                                <option disabled value="" selected>
                                    {{ $t("Select a period") }}
                                </option>
                                <option value="3">{{ $t("3 months") }}</option>
                                <option value="6">{{ $t("6 months") }}</option>
                                <option value="12">{{ $t("1 Year") }}</option>
                                <option value="24">{{ $t("2 years") }}</option>
                                <option value="36">{{ $t("3 years") }}</option>
                                <option value="48">{{ $t("4 years") }}</option>
                                <option value="60">{{ $t("5 years") }}</option>
                                <option value="99">
                                    {{ $t("Permanent exclusion") }}
                                </option>
                            </select>
                        </div>

                        <div
                            class="flex justify-between items-end w-full col-span-2 mt-4"
                        >
                            <button
                                v-if="selectedOption !== '1'"
                                class="save-button"
                                :disabled="!selectedOption"
                            >
                                {{ $t("Save") }}
                            </button>
                            <button
                                v-if="selectedOption === '1'"
                                class="cancel-button"
                            >
                                {{ $t("X Cancel account") }}
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </BaseProfileLayout>
</template>

<style scoped>
.description {
    font-size: 0.775rem;
}

.radio-buttons {
    gap: 2rem;
}

.radio-label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: var(--text-color);
    justify-content: flex-start;
    gap: 4px;
}

.radio-input {
    accent-color: var(--primary-color);
    transform: scale(1.2);
}

.save-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--ci-primary-color);
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.save-button:hover {
    background-color: var(--background-color);
}

.cancel-button {
    padding: 0.5rem 1rem;
    background-color: var(--ci-primary-color);
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: var(--background-color);
}

.select {
    flex: 1;
    min-width: 120px;
    max-width: 400px;
    background-color: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: var(--text-color);
}
</style>

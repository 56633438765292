<script setup>
import { ref } from "vue";

const props = defineProps(["game"]);

const providerName = ref(props.game?.provider?.name);
const gameName = ref(props.game?.game_name);
</script>

<template>
    <div class="bg-[var(--navtop-color-dark)] rounded flex justify-between px-4 py-2">
        <div class="flex items-center justify-center gap-3">
            <a href="">{{ providerName }}</a>
            <i class="fa-regular fa-angle-right text-gray-500"></i>
            <p class="text-gray-500">{{ gameName }}</p>
        </div>
    </div>
</template>

import { defineStore } from "pinia";

export const useSocialLinksStore = defineStore("socialLinks", {
	state() {
		return {
			links: [],
		};
	},

	actions: {
		async fetch() {
			try {
				const response = await fetch("/api/layout/social-media-links");
				if (response.ok) {
					const data = await response.json();
					this.links = data.map((item) => ({
						name: item.name,
						url: item.url,
						icon: `/assets/images/icons/${item.name.toLowerCase()}.svg`,
					}));
				} else {
					console.error("Erro ao buscar links de redes sociais.");
				}
			} catch (error) {
				console.error("Erro ao conectar à API:", error);
			}
		},
	},
});

<script setup>
import { ref, computed, watch } from "vue";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";
import { Carousel, Slide } from "vue3-carousel";
import "/resources/css/games.css";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const props = defineProps({
	featuredGames: {
		type: Array,
		required: true,
	},
	index: {
		type: Number,
		default: 0,
	},
});

const ckCarousel = ref(null);

const reactiveBreakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = reactiveBreakpoints.smaller("md");

const settingsGames = {
	itemsToShow: 3,
	snapAlign: "start",
};

const breakpointsGames = {
	700: {
		itemsToShow: 3,
		snapAlign: "center",
	},
	1024: {
		itemsToShow: 6,
		snapAlign: "start",
	},
};

const maxItemsToShow = computed(() => {
	if (reactiveBreakpoints.isGreaterOrEqual("lg")) return 6;
	if (reactiveBreakpoints.isGreaterOrEqual("md")) return 3;
	return 3;
});

const showNavigation = computed(() => {
	return (
		props.featuredGames && props.featuredGames.length > maxItemsToShow.value
	);
});

const currentSlide = ref(props.index);

const totalSlides = computed(() => {
	return props.featuredGames ? props.featuredGames.length : 0;
});

const canGoPrev = computed(() => currentSlide.value > 0);

const canGoNext = computed(
	() => currentSlide.value + maxItemsToShow.value < totalSlides.value,
);

const onCarouselInit = (event) => {
	if (event && typeof event.currentSlideIndex === "number") {
		currentSlide.value = event.currentSlideIndex;
	} else {
		currentSlide.value = 0;
	}
};

const onSlideEnd = (event) => {
	if (event && typeof event.currentSlideIndex === "number") {
		currentSlide.value = event.currentSlideIndex;
	} else {
		currentSlide.value = 0;
	}
};

watch(maxItemsToShow, () => {
	if (currentSlide.value + maxItemsToShow.value > totalSlides.value) {
		currentSlide.value = Math.max(totalSlides.value - maxItemsToShow.value, 0);
		if (ckCarousel.value) {
			ckCarousel.value.goTo(currentSlide.value);
		}
	}
});
</script>

<template>
    <div v-if="featuredGames" class="max-w-6xl mx-auto">
        <div class="mb-4 flex w-full items-center justify-between">
            <div class="flex gap-6 items-center">
                <h2 class="heading-style">
                    {{ $t('Featured') }}
                </h2>

                <div v-if="!isMobile && showNavigation" class="flex items-center">
                    <button
                        v-if="canGoPrev"
                        @click.prevent="ckCarousel.prev()"
                        class="item-game px-2 py-1 rounded mr-2 bg-[var(--ci-primary-color)] text-white text-sm"
                    >
                        <i class="fa-solid fa-angle-left text-xs"></i>
                    </button>
                    <button
                        v-if="canGoNext"
                        @click.prevent="ckCarousel.next()"
                        class="item-game px-2 py-1 rounded bg-[var(--ci-primary-color)] text-white text-sm"
                    >
                        <i class="fa-solid fa-angle-right text-xs"></i>
                    </button>
                </div>
            </div>
            <div class="flex">
                <RouterLink :to="{ name: 'featuredGames' }"
                    class="inline-flex items-center justify-end bg-[var(--ci-primary-color)] rounded-full text-xs font-medium ml-auto px-2 py-0.5 gap-1 no-underline"
                    style="line-height: 1rem;">
                    <span style="font-size: 0.6rem; font-weight: 600; line-height: 1rem;">
                        {{ $t('See all') }}
                    </span>
                </RouterLink>
            </div>
        </div>

        <Carousel
            ref="ckCarousel"
            v-bind="settingsGames"
            :breakpoints="breakpointsGames"
            @init="onCarouselInit"
            @slide-end="onSlideEnd"
        >
            <Slide v-for="(game, index) in featuredGames" :key="game.id || index">
                <CassinoGameCard
                    :index="index"
                    :title="game.game_name"
                    :cover="game.cover"
                    :gamecode="game.game_code"
                    :type="game.distribution"
                    :game="game"
                />
            </Slide>
        </Carousel>
    </div>
</template>

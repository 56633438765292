import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useHighlightedButtonStore = defineStore("HighlightedButtonStore", {
	state() {
		return {
			buttons: [],
		};
	},

	actions: {
		async fetch() {
			try {
				await HttpApi.get("/layout/highlight-buttons").then((response) => {
					let data = response.data;

					data = data.map((button) => {
						return {
							title: button.title,
							link: button.link,
							text_color: button.text_color,
							background_color: button.background_color,
							border_color: button.border_color,
							emoji: button.emoji,
							icon: button.icon,
						};
					});

					this.buttons = [...data];
				});
			} catch (error) {
				console.error("Erro ao buscar os botões:", error);
			}
		},
	},
});

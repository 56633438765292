<script setup>
import { ref } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Shortcuts from "@/Components/Home/Shortcuts.vue";
import RecommendGames from "@/Components/Home/RecommendGames.vue";
import SearchGames from "@/Components/Home/SearchGames.vue";

const props = defineProps({
	banners: Array,
	bannersHome: Array,
	hideExtras: {
		// Nova prop
		type: Boolean,
		default: false,
	},
});

const settings = ref({
	itemsToShow: 1,
	snapAlign: "center",
	autoplay: 6000,
	wrapAround: true,
});

const breakpoints = ref({
	700: {
		itemsToShow: 1,
		snapAlign: "center",
	},
	1024: {
		itemsToShow: 1,
		snapAlign: "center",
	},
});
</script>

<template>
    <div class="carousel-banners">
        <div class="max-w-6xl xl:px-2 mx-auto py-2 relative">
            <div class="mt-10 mb-5 relative z-5 px-2 md:px-0">
                <Carousel v-bind="settings" :breakpoints="breakpoints">
                    <Slide v-for="(banner, index) in banners" :key="index">
                        <div class="carousel__item rounded w-full">
                            <a
                                :href="banner.link"
                                class="w-full h-full bg-blue-800 rounded"
                            >
                                <img
                                    :src="`/storage/` + banner.image"
                                    alt=""
                                    class="h-full w-full rounded"
                                />
                            </a>
                        </div>
                    </Slide>
                    <template #addons>
                        <navigation>
                            <template #next>
                                <i
                                    class="fa-solid fa-chevron-right text-white custom-arrow right-0"
                                ></i>
                            </template>
                            <template #prev>
                                <i
                                    class="fa-solid fa-chevron-left text-white custom-arrow left-0"
                                ></i>
                            </template>
                        </navigation>
                        <Pagination />
                    </template>
                </Carousel>
            </div>
            <SearchGames v-if="!hideExtras" />
            
            <Shortcuts v-if="!hideExtras" />
            
            <RecommendGames :banners="bannersHome" v-if="!hideExtras" />
        </div>
    </div>
</template>
<style scoped>
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 50%;
}

.custom-arrow.left-0 {
    left: 10px;
}

.custom-arrow.right-0 {
    right: 10px;
}

@media (min-width: 1024px) {
    .custom-arrow {
        font-size: 2rem;
        padding: 1rem;
    }
}
</style>

<script setup>
defineProps(["games", "isLoading"]);

const emit = defineEmits(["loadMoreGames"]);

const loadMoreGames = () => {
	emit("loadMoreGames");
};
</script>

<template>
    <div class="mt-4 text-center" v-if="games && games.data.length < games.total">
        <p>
            {{ $t("Showing") }} {{ games?.data.length || 0 }}
            {{ $t("of") }} {{ games?.total || 0 }}
            {{ $t("games") }}
        </p>
        <button
            v-if="!isLoading"
            class="pagination-button mt-2"
            @click="loadMoreGames"
        >
            {{ $t("Load more games") }}
        </button>
        <button
            v-if="isLoading"
            class="pagination-button mt-2"
            disabled
        >
            {{ $t("Loading...") }}
        </button>
    </div>
</template>

<style scoped>
.pagination-button {
    background-color: var(--ci-primary-color);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

</style>

<script setup>
import { Carousel, Slide } from "vue3-carousel";
import CassinoGameCard from "@/Pages/Cassino/Components/CassinoGameCard.vue";
import { ref } from "vue";

const ckCarousel = ref(null);
const props = defineProps(["games"]);

const settingsGames = {
	itemsToShow: 2.5,
	snapAlign: "start",
};

const onCarouselInit = (index) => {};
const onSlideStart = (index) => {};

const breakpointsGames = {
	700: {
		itemsToShow: 3.5,
		snapAlign: "center",
	},
	1024: {
		itemsToShow: 6,
		snapAlign: "start",
	},
};
</script>

<template>
    <div v-if="!games" class="text-center mt-12">
        <span>{{ $t('Loading Related Games') }}...</span>
    </div>

    <div v-if="games" class="max-w-6xl mx-auto mt-12">
        <div class="mb-2 flex w-full items-center justify-between">
            <div class="flex gap-6 items-center">
                <h2 class="text-xl font-bold">{{ $t('Featured') }}</h2>

                <div class="flex items-center">
                    <button @click.prevent="ckCarousel.prev()" class="item-game px-3 py-2 rounded mr-2">
                        <i class="fa-solid fa-angle-left"></i>
                    </button>
                    <button @click.prevent="ckCarousel.next()" class="item-game px-3 py-2 rounded">
                        <i class="fa-solid fa-angle-right"></i>
                    </button>
                </div>
            </div>
            <div class="flex">
                <RouterLink
                    :to="{ name: 'featuredGames' }"
                    class="inline-block rounded-full bg-[var(--ci-primary-color)] px-3 py-1 text-sm font-medium text-[var(--title-color)]">
                    {{ $t('See all') }}
                </RouterLink>
            </div>
        </div>

        <Carousel ref="ckCarousel"
                  v-bind="settingsGames"
                  :breakpoints="breakpointsGames"
                  @init="onCarouselInit"
                  @slide-start="onSlideStart"
        >
            <Slide v-if="games" v-for="(game, index) in games" :index="index">
                <CassinoGameCard
                    :index="providerId"
                    :title="game.game_name"
                    :cover="game.cover"
                    :gamecode="game.game_code"
                    :type="game.distribution"
                    :game="game"
                />
            </Slide>
        </Carousel>
    </div>
</template>


<script setup>
const emit = defineEmits(["toggle-menu"]);

const toggleMenu = () => {
	emit("toggle-menu");
};
</script>

<template>
    <button @click="toggleMenu" type="button" class="mr-4 hidden cursor-pointer sm:block">
        <svg height="24px" viewBox="0 0 448 512" width="26px"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                fill="#fff"></path>
        </svg>
    </button>
</template>


<script setup>
import { ref, onMounted } from "vue";
import Balance from "@/Components/Wallet/Balance.vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";

const isLoadingWallet = ref(true);
const wallet = ref(null);
const mywallets = ref(null);

const getWallet = () => {
	const _toast = useToast();
	isLoadingWallet.value = true;

	HttpApi.get("profile/wallet")
		.then((response) => {
			wallet.value = response.data.wallet;
			isLoadingWallet.value = false;
		})
		.catch((error) => {
			Object.entries(JSON.parse(error.request.responseText)).forEach(
				([key, value]) => {
					_toast.error(`${value}`);
				},
			);
			isLoadingWallet.value = false;
		});
};

const getMyWallet = () => {
	const _toast = useToast();

	HttpApi.get("profile/mywallet")
		.then((response) => {
			mywallets.value = response.data.wallets;
		})
		.catch((error) => {
			Object.entries(JSON.parse(error.request.responseText)).forEach(
				([key, value]) => {
					_toast.error(`${value}`);
				},
			);
		});
};

onMounted(() => {
	useMenuBarStore().setActiveMenu("wallet");
	getWallet();
	getMyWallet();
});
</script>

<template>
    <BaseProfileLayout>
        <Balance :wallet="wallet" :isLoadingWallet="isLoadingWallet"/>
    </BaseProfileLayout>
</template>


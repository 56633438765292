<script setup>
import { ref } from "vue";
import { component as FullscreenComponent } from "vue-fullscreen";

const props = defineProps({
	game: Object,
	gameUrl: String,
	showButton: Boolean,
});

const fullscreen = ref(false);
const pageOnly = ref(false);

function openModal(url) {
	window.open(url);
}
</script>

<template>
    <section>
        <FullscreenComponent v-model="fullscreen" :page-only="pageOnly">
            <div v-if="showButton && game.game_type === 'live' && game.distribution === 'evergame'"
                 class="game-full fullscreen-wrapper flex items-center justify-center">
                <button @click.prevent="openModal(gameUrl)" type="button"
                        class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Clique para começar
                </button>
            </div>
            <iframe v-else :src="gameUrl" class="game-full fullscreen-wrapper"></iframe>
        </FullscreenComponent>
    </section>
</template>

<style scoped>
.game-full {
    width: 100%;
    min-height: 650px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
</style>

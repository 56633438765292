<script setup>
import { ref } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const props = defineProps({
	banners: Array,
});

const reactiveBreakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = reactiveBreakpoints.smaller("md");

const settings = ref({
	itemsToShow: 2,
	snapAlign: "start",
});

const breakpoints = ref({
	0: {
		itemsToShow: 3,
		snapAlign: "start",
	},
});
</script>

<template>
    <div class="recommended-games mb-5 relative z-5" id="recommended-games">
        <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(banner, index) in banners" :key="index">
                <div class="carousel__item !min-h-[60px] md:!min-h-[150px] rounded w-full mr-4">
                    <a :href="banner.link"
                       class="rounded-md shadow-md overflow-hidden flex items-center justify-center home_banner_link">
                        <img v-if="isMobile" :src="'/storage/' + banner.mobile_image" :alt="'Banner' + index"
                             class="mobile_image_banner">
                        <img v-else :src="'/storage/' + banner.image" :alt="'Banner' + index"
                             class="object-contain object-center">
                    </a>
                </div>
            </Slide>
        </Carousel>
    </div>
</template>

<style scoped>
.mobile_image_banner {
    object-fit: contain;
    object-position: center;
    background: transparent !important;
    max-height: 175px !important;
    max-width: 132px !important;
}
.home_banner_link {
    background: transparent !important;
}
</style>

<script setup>
import { ref } from "vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";

const isLoading = ref(false);
const form = ref({
	email: "",
});

const forgotPasswordSubmit = async () => {
	isLoading.value = true;
	const _toast = useToast();

	try {
		await HttpApi.post("auth/forget-password", form.value);
		_toast.success("Um token foi enviado para você em sua caixa de E-mail!");
	} catch (error) {
		Object.entries(JSON.parse(error.request.responseText)).forEach(
			([key, value]) => {
				_toast.error(`${value}`);
			},
		);
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
    <form @submit.prevent="forgotPasswordSubmit" method="post" action="" class="">
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-envelope text-success-emphasis"></i>
            </div>
            <input type="email"
                   name="email"
                   v-model="form.email"
                   class="input-group"
                   :placeholder="$t('Enter email or phone')"
                   required
            >
        </div>
        <div class="mt-5 w-full">
            <button type="submit" class="ui-button-blue rounded w-full mb-3">
                {{ $t('Reset Password') }}
            </button>
        </div>
    </form>
</template>

import { defineStore } from "pinia";

export const useModalStore = defineStore("ModalStore", {
	state() {
		return {
			isDepositModalOpen: false,
		};
	},

	actions: {
		openDepositModal() {
			this.isDepositModalOpen = true;
		},

		closeDepositModal() {
			this.isDepositModalOpen = false;
		},
	},
});

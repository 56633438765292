<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";
import LimitSection from "@/Components/Profile/AccountLimits/LimitsSection.vue";

const menuBarStore = useMenuBarStore();
const authStore = useAuthStore();
const isLoading = ref(true);
const profile = ref();

const expandedSections = ref({
	depositLimit: false,
	betLimit: false,
	lossLimit: false,
	timeLimit: false,
});

const fetchUserData = async () => {
	try {
		const response = await HttpApi("profile");
		profile.value = response.data;
	} catch (error) {
		console.error("Erro ao carregar os dados do usuário:", error);
		if (error.response && error.response.status === 401) {
			await authStore.logout();
		}
	} finally {
		isLoading.value = false;
	}
};

onMounted(() => {
	menuBarStore.setActiveMenu("profile");
	fetchUserData();
});

const toggleSection = (section) => {
	expandedSections.value[section] = !expandedSections.value[section];
};
</script>

<template>
    <BaseProfileLayout>
        <div>
            <div class="mb-4 profile-section-container">
                <h1 class="text-sm font-bold" style="color: var(--title-color)">
                    {{ $t('Limites da Conta') }}
                </h1>
            </div>

            <div class="profile-section-container">
                <div v-if="isLoading">
                    <p class="mb-6 text-lg" style="color: var(--text-color)">
                        {{$t('Carregando informações...')}}
                    </p>
                </div>

                <div v-else>
                    <div class="mb-6">
                        <p class="description">
                            {{
                                $t("Os limites da conta permitem que você defina restrições personalizadas para gerenciar melhor o uso da sua conta. Você pode definir limites financeiros, de tempo e de perdas para garantir que o controle esteja sempre em suas mãos. Estes limites podem ser ajustados para períodos diários, semanais, mensais ou anuais, dependendo da sua preferência. Caso queira retirar ou modificar algum limite, o ajuste só poderá ser feito após um período de carência de 24 horas.")
                            }}
                        </p>
                    </div>

                    <LimitSection
                        v-for="(isExpanded, key) in expandedSections"
                        :key="key"
                        :section-key="key"
                        :is-expanded="isExpanded"
                        @toggle-section="toggleSection"
                    />
                </div>
            </div>
        </div>
    </BaseProfileLayout>
</template>

<style scoped>
.description {
    font-size: 0.775rem;
}
</style>

<script setup>
import { ref, onMounted, computed, reactive, onUnmounted } from "vue";
import { useSettingStore } from "@/Stores/SettingStore.js";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import ButtonsHighlighted from "@/Components/Sidebar/ButtonsHighlighted.vue";
import Menu from "@/Components/Sidebar/Menu.vue";
import Footer from "@/Components/Sidebar/Footer.vue";
import { useTextBannerStore } from "@/Stores/TextBannerStore.js";
import { sidebarStore } from "@/Stores/SideBarStore.js";
import { useCategoryStore } from "@/Stores/CategoryStore.js";
import { useFooterLinkStore } from "@/Stores/FooterLinkStore.js";
import ButtonsHighlightedIcons from "@/Components/Sidebar/ButtonsHighlightedIcons.vue";
import MenuIcons from "@/Components/Sidebar/MenuIcons.vue";
import FooterIcons from "@/Components/Sidebar/FooterIcons.vue";

const textBannerStore = useTextBannerStore();
const settingStore = useSettingStore();
const categoriesStore = useCategoryStore();
const footerLinkStore = useFooterLinkStore();

const isMobile = ref(window.innerWidth < 768);
const isLoading = computed(() => categoriesStore.fetching);

const categories = computed(() => categoriesStore.categories);
const footerLinks = computed(() => footerLinkStore.links);
const showTextBanner = computed(() => textBannerStore.showTextBanner);

const setting = ref(null);

const getSetting = () => {
	const settingData = settingStore.setting;
	if (settingData) {
		setting.value = settingData;
	}
};

const handleResize = () => {
	isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
	getSetting();

	if (window.innerWidth < 1024) {
		sidebarStore().setSidebarStatus(false);
	}

	window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
	window.removeEventListener("resize", handleResize);
});
</script>

<template>
    <aside v-show="(isMobile && sidebarStore().sidebarStatus) || (!isMobile && sidebarStore().sidebarStatus)"
           :class="[
              showTextBanner ? 'lg:pt-[40px]' : 'lg:pt-0',
              'sm:w-64',
              'w-full'
            ]"
           class="md:max-w-[250px] bg-red-500 fixed left-0 z-[99] h-screen transition-transform sm:translate-x-0 sidebar-color custom-side-shadow border-r-[1px] border-r-slate-50 border-opacity-10"
           aria-label="Sidebar">

        <div class="h-full pb-4 overflow-y-auto sidebar-color">
            <ButtonsHighlighted/>
            <template v-if="!isLoading" >
                <Menu :categories="categories"/>
            </template>
            <Footer :links="footerLinks"/>
        </div>
    </aside>
    <div v-if="!isMobile">
        <aside v-show="(!isMobile && !sidebarStore().sidebarStatus)"
               :class="[
              showTextBanner ? 'lg:pt-[35px]' : 'lg:pt-0',
              'sm:w-[70px]',
              'w-[50px]',
            ]"
               class="fixed left-0 z-[99] w-full-mobile h-screen transition-transform  sm:translate-x-0 sidebar-color custom-side-shadow border-r-[1px] border-r-slate-100 border-opacity-10"
               id="icon-sidebar"
               aria-label="Sidebar">
            <div class="h-full pb-4 overflow-y-auto sidebar-color">
                <ButtonsHighlightedIcons/>
                <template v-if="!isLoading" >
                    <MenuIcons :categories="categories"/>
                </template>
                <FooterIcons :links="footerLinks"/>
            </div>
        </aside>
    </div>
</template>

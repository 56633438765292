<script setup>
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const reactiveBreakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = reactiveBreakpoints.smaller("md");
</script>

<template>
    <div class="w-full animate-skeleton bg-gray-400 rounded-lg"
         :class="{ 'h-48': !isMobile }"
         :style="{ borderRadius: '.375rem', height: isMobile ? '9.525rem' : '' }">
        <span class="sr-only">Loading...</span>
    </div>
</template>

<style scoped>
    .animate-skeleton {
        animation: skeleton 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    @keyframes skeleton {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
</style>

<script setup>
import { computed } from "vue";
import { useSocialLinksStore } from "@/Stores/SocialLinksStore.js";
import SocialLink from "@/Components/Footer/SocialLink.vue";

const socialLinksStore = useSocialLinksStore();
const socialMediaLinks = computed(() => socialLinksStore.links);
</script>

<template>
    <div class="text-center">
        <img :src="`/assets/images/icons/flamix.png`" alt="flamix" width="300" class="!pl-2 mb-6"/>
        <div class="flex flex-wrap justify-start gap-x-4 gap-y-4 ml-0">
            <div v-for="link in socialMediaLinks" :key="link.name" class="text-center">
                <SocialLink :link="link"/>
            </div>
        </div>
    </div>
</template>

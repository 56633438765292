<script setup>
import { useHighlightedButtonStore } from "@/Stores/HighlightedButtonStore.js";
import { computed } from "vue";

const highlightedButtonStore = useHighlightedButtonStore();

const buttons = computed(() => highlightedButtonStore.buttons);
</script>

<template>
    <div class="flex flex-col gap-y-4 border-b-[1px] border-b-slate-50 border-opacity-10 p-4">
        <template v-for="button in buttons">
            <button @click="button.action" class="highlight-link btn-bonus btn-width"
                    :style="{background: button.background_color, borderColor: button.border_color}">

                <span class="flex w-full items-center justify-between gap-2 text-center font-bold lg:justify-center" :style="{color: button.text_color}">
                    <span class="text-sm">{{ button.title }}</span>
                    <span class="collapse-visible text-xl">{{ button.emoji }}</span>
                </span>

            </button>
        </template>
    </div>
</template>

<style scoped>
.sidebar-highlight {
    margin-bottom: 20px;
}

.btn-bonus {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<script setup>
import useLogoutAccount from "@/composables/useLogoutAccount.js";

const logoutAccount = () => useLogoutAccount();
</script>

<template>
    <li class="w-full opacity-custom">
        <button @click="logoutAccount"
             class="flex justify-between items-center w-full bg-custom hover-bg-custom dark-bg-custom px-4 py-3 rounded hover-dark-bg-custom transition duration-700 cursor-pointer">
            <div class="flex items-center">
                <span class="w-8"><i class="fa-solid fa-right-from-bracket"></i></span>
                <span>{{ $t('To Exit') }}</span>
            </div>
            <span><i class="fa-solid fa-chevron-right"></i></span>
        </button>
    </li>
</template>

<script setup>
import { useRouter } from "vue-router";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref } from "vue";

const props = defineProps(["index", "game"]);
const router = useRouter();
const reactiveBreakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = reactiveBreakpoints.smaller("md");

const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const threshold = 5;

const onInteractionEnd = () => {
	setTimeout(() => {
		isDragging.value = false;
	}, 0);
};

const onMouseDown = (e) => {
	isDragging.value = false;
	startX.value = e.clientX;
	startY.value = e.clientY;
};

const onMouseMove = (e) => {
	const deltaX = Math.abs(e.clientX - startX.value);
	const deltaY = Math.abs(e.clientY - startY.value);
	if (deltaX > threshold || deltaY > threshold) {
		isDragging.value = true;
	}
};

const onMouseUp = () => {
	onInteractionEnd();
};

const onMouseLeave = () => {
	isDragging.value = false;
};

const onTouchStart = (e) => {
	isDragging.value = false;
	const touch = e.touches[0];
	startX.value = touch.clientX;
	startY.value = touch.clientY;
};

const onTouchMove = (e) => {
	const touch = e.touches[0];
	const deltaX = Math.abs(touch.clientX - startX.value);
	const deltaY = Math.abs(touch.clientY - startY.value);
	if (deltaX > threshold || deltaY > threshold) {
		isDragging.value = true;
	}
};

const onTouchEnd = () => {
	onInteractionEnd();
};

const handleClick = () => {
	if (!isDragging.value) {
		router.push({
			name: "casinoPlayPage",
			params: { id: props.game.id, slug: props.game.game_code },
		});
	}
};
</script>

<template>
        <div
          class="text-gray-700 w-full h-auto mr-4 cursor-pointer"
          :style="{height: 'auto'}"
          @mousedown="onMouseDown"
          @mousemove="onMouseMove"
          @mouseup="onMouseUp"
          @mouseleave="onMouseLeave"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
          @click.prevent="handleClick"
        >
          <img
            :src="game.distribution === 'kagaming' ? game.cover : '/storage/' + game.cover"
            alt=""
            class="w-full"
            :class="{ 'h-48': !isMobile }"
            :style="{ borderRadius: '.375rem', height: isMobile ? '9.525rem' : '' }"
          >
        </div>
</template>

<style scoped>
.user-select-none {
  user-select: none;
}
</style>

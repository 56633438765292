<template>
    <BaseLayout>

    </BaseLayout>
</template>


<script>
import BaseLayout from "@/Layouts/BaseLayout.vue";

export default {
	props: [],
	components: { BaseLayout },
	data() {
		return {
			isLoading: false,
		};
	},
	setup(props) {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {},
	watch: {},
};
</script>

<style scoped>

</style>

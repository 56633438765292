<script setup>
import { ref } from "vue";

const isExpanded = ref(false);
</script>

<template>
    <div class="relative py-6 after:absolute after:left-0 after:top-full after:h-px after:w-full after:bg-white after:opacity-20 after:content-['']">
        <div :class="`text-footer-texts pb-2 text-center text-[12px] text-gray-600 dark:text-gray-400 ${isExpanded ? '' : 'max-h-16 custom-overflow-hidden'}`">
            <p>A Flamix Casino | Cassino Online é um site de apostas esportivas e cassino online, que oferece aos seus usuários uma experiência única de apostas em diversos esportes, incluindo futebol, basquete, tênis, vôlei, entre outros.</p>
            <p>Com uma plataforma intuitiva e fácil de usar, a Flamix Casino | Cassino Online permite que seus usuários apostem em jogos ao vivo, além de oferecer uma ampla variedade de opções de apostas pré-jogo.</p>
            <p>Com o objetivo de proporcionar a melhor experiência de apostas aos seus usuários, oferecemos uma ampla gama de recursos e ferramentas, incluindo estatísticas detalhadas dos jogos, histórico de resultados, análises de especialistas e muito mais.</p>
            <p>Além disso, o site oferece diversas promoções e bônus exclusivos para seus usuários, aumentando as chances de lucro e proporcionando uma experiência ainda mais emocionante.</p>
            <p>Somos uma plataforma segura e confiável, que oferece suporte ao cliente 24 horas por dia, sete dias por semana. Com uma equipe de especialistas em apostas esportivas e cassino online, Donald Bet está comprometida em garantir que seus usuários tenham uma experiência positiva e segura, e possam desfrutar de todas as emoções e oportunidades oferecidas pelas apostas esportivas de forma responsável e consciente.</p>
        </div>
        <button @click="isExpanded = !isExpanded" class="pt-12 absolute bottom-[-20px] z-10 w-full bg-gradient-to-t from-[var(--background-base)] to-transparent text-[.7rem] text-gray-600 dark:text-gray-400 md:bottom-[-1px]">
            {{ isExpanded ? 'Ver menos' : 'Ver mais' }}
        </button>
    </div>
</template>

<script setup>

</script>

<template>
    <div class="profile-section-container">
        <span class="font-bold text-sm">
            {{ $t('Minhas Indicações').toUpperCase() }}:
        </span>
    </div>
</template>

import {
	ref as databaseRef,
	set,
} from "https://www.gstatic.com/firebasejs/10.5.2/firebase-database.js";

const apiKey = "5db4063b29f77a538a6963b367995c16";
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;
const uriPath = "https://v3.football.api-sports.io/";

export function useApiSports(database) {
	const getLeagues = async () => {
		const headers = {
			"x-apisports-key": apiKey,
			Accept: "application/json",
		};

		const response = await fetch(
			`https://v3.football.api-sports.io/leagues?season=${year}&current=true`,
			{
				method: "GET",
				headers: headers,
			},
		);

		if (!response.ok) {
			throw new Error("The network response was not correct");
		}

		const data = await response.json();
		const fixturesRef = databaseRef(database, "leagues");
		await set(fixturesRef, data);
		return data;
	};

	const getFixturesLive = async () => {
		const headers = {
			"x-apisports-key": apiKey,
			Accept: "application/json",
		};

		const response = await fetch(`${uriPath}fixtures/live`, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			throw new Error("The network response was not correct");
		}

		const data = await response.json();
		const fixturesRef = databaseRef(database, "fixtures-live");
		await set(fixturesRef, data);
		return data;
	};

	const getFixtureById = async (id) => {
		const headers = {
			"x-apisports-key": apiKey,
			Accept: "application/json",
		};

		const response = await fetch(`${uriPath}fixtures?id=${id}`, {
			method: "GET",
			headers: headers,
		});

		return await response.json();
	};

	const getOdds = async () => {
		const headers = {
			"x-apisports-key": apiKey,
			Accept: "application/json",
		};

		const response = await fetch(`${uriPath}odds/live`, {
			method: "GET",
			headers: headers,
		});

		if (!response.ok) {
			throw new Error("The network response was not correct");
		}

		const data = await response.json();
		const promises = data.response.map(async (odd) => {
			const result = await getFixtureById(odd.fixture.id);
			odd.fixture = result.response[0];
			return odd;
		});

		const updatedOdds = await Promise.all(promises);
		const saveData = { ...data, response: updatedOdds };
		const fixturesRef = databaseRef(database, "fixtures-live-" + formattedDate);
		await set(fixturesRef, saveData);
		return saveData;
	};

	const getFixtures = async () => {
		const headers = {
			"x-apisports-key": apiKey,
			Accept: "application/json",
		};

		const response = await fetch(
			`https://v3.football.api-sports.io/fixtures?status=NS&date=${formattedDate}&season=${year}`,
			{
				method: "GET",
				headers: headers,
			},
		);

		if (!response.ok) {
			throw new Error("The network response was not correct");
		}

		const data = await response.json();
		const fixturesRef = databaseRef(database, "fixtures-" + formattedDate);
		await set(fixturesRef, data);
		return data;
	};

	return { getLeagues, getFixturesLive, getOdds, getFixtures };
}

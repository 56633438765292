<script setup>
import { onMounted, ref, computed } from "vue";
import { useTextBannerStore } from "@/Stores/TextBannerStore.js";
import { useAuthStore } from "@/Stores/Auth.js";
import { useRouter } from "vue-router";

const banner = computed(() => textBannerStore.banner);
const textBannerStore = useTextBannerStore();

const showTextBanner = computed(() => textBannerStore.showTextBanner);
const authStore = useAuthStore();
const isAuth = computed(() => authStore.isAuth);
const router = useRouter();

function closeBanner() {
	textBannerStore.close();
}

function loginToggle() {
	router.push({ query: { login: "true" } });
}
</script>
<template>
    <transition name="fade">
        <div v-if="showTextBanner && banner"
             class="relative flex items-center justify-center gap-2 px-2 py-0 md:px-4 md:py-2"
             :style="{ backgroundColor: banner.background_color }">
            <p class="reedem py-[1px] text-center font-medium text-white text-xs lg:text-sm"
               :style="{ color: banner.text_color }">
                {{ banner.text }}
            </p>

            <a v-if="banner.has_button && isAuth" :href="banner.button_link"
               class="rounded p-1 px-2 text-[10px] font-medium hover:opacity-90 lg:text-sm"
               :style="{ backgroundColor: banner.button_color, color: banner.button_text_color }">
                {{ banner.button_text }}
            </a>

            <a v-else-if="banner.has_button && !isAuth" @click="loginToggle"
               class="rounded p-1 px-2 text-[10px] font-medium hover:opacity-90 lg:text-sm"
               :style="{ backgroundColor: banner.button_color, color: banner.button_text_color }">
                {{ banner.button_text }}
            </a>

            <button @click="closeBanner()" type="button"
                    class="group py-1 md:absolute md:right-2 md:top-2/4 md:-translate-y-1/2 md:p-1">
                <svg height="12" viewBox="0 0 320 512" width="1em" xmlns="http://www.w3.org/2000/svg"
                     class="fill-[#fff] group-hover:fill-black">
                    <path
                        d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                </svg>
            </button>
        </div>
    </transition>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores do Vue */ {
    opacity: 0;
}
</style>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const text = ref("Volver Arriba");
const isVisible = ref(false);

const returnTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};

const handleScroll = () => {
	isVisible.value = window.scrollY > 150;
};

onMounted(() => {
	window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
	window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
    <button @click="returnTop" v-show="isVisible"
            class="flex fixed bottom-20 left-2/4 z-[50] -translate-x-2/4 cursor-pointer items-center
            justify-center gap-2 rounded-full border border-[var(--ci-primary-color)] bg-black bg-opacity-80 px-2
            py-1 hover:brightness-110 lg:bottom-5 lg:translate-x-2/4 lg:px-4 lg:py-2">
        <div>
            <svg height="12" viewBox="0 0 384 512" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
                    fill="#fff"></path>
            </svg>
        </div>
        <span class="text-xs text-white">
            {{ text }}
        </span>
    </button>
</template>

<script setup>
import { ref } from "vue";

const copied = ref(false);
const props = defineProps(["link"]);

const copy = () => {
	if (!props.link) {
		return;
	}

	copied.value = true;
	navigator.clipboard.writeText(props.link);

	setTimeout(() => {
		copied.value = false;
	}, 2000);
};
</script>

<template>
    <button @click="copy" class="flex gap-2 items-center p-4 font-bold" style="background-color: var(--ci-primary-color); color: var(--ci-primary-color); border-radius: 0.5rem; max-height: 50px;">
        <svg v-show="!copied" class="w-5 text-gray-900" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M512 96V336C512 362.508 490.51 384 464 384H272C245.49 384 224 362.508 224 336V48C224 21.492 245.49 0 272 0H416V96H512Z"
                fill="currentColor"></path>
            <path
                d="M192 352V128H48C21.49 128 0 149.492 0 176V464C0 490.508 21.49 512 48 512H240C266.51 512 288 490.508 288 464V416H256C220.652 416 192 387.344 192 352ZM416 0V96H512L416 0Z"
                fill="currentColor" opacity="0.4"></path>
        </svg>

        <span class="text-gray-900">
            {{ copied ? $t('Link Copiado') : $t('Copiar Link') }}
        </span>
    </button>
</template>

<style scoped>

</style>

<script setup>
import { ref } from "vue";

const props = defineProps({
	searchTerm: String,
});
const emit = defineEmits(["input"]);

const localSearchTerm = ref(props.searchTerm);

const handleInput = () => {
	emit("input", localSearchTerm.value);
};
</script>

<template>
    <form class="mb-5">
        <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{{ $t('Search') }}</label>
        <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input v-model="localSearchTerm" @input="handleInput" type="search" id="search" class="block w-full p-4 pl-10 text-sm rounded-lg" placeholder="Search" required>
            <button type="submit" class="bg-[var(--ci-primary-color)] text-white absolute right-2.5 bottom-2.5  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2">
                {{ $t('Search') }}
            </button>
        </div>
    </form>
</template>

<script setup>
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";

const menuBarStore = useMenuBarStore();

const props = defineProps(["label"]);
const returnMenu = () => {
	menuBarStore.setActiveMenu("default");
};
</script>

<template>
    <li class="w-full mb-2">
        <button @click="returnMenu"
                class="flex justify-between items-center w-full bg-custom hover-bg-custom dark-bg-custom px-4 py-3 rounded hover-dark-bg-custom transition duration-700 cursor-pointer">
            <div class="flex items-center">
                <span class="w-8"><i class="fa-solid fa-chevron-left"></i></span>
                <span>{{ label }}</span>
            </div>
        </button>
    </li>
</template>

<script setup>
import { ref } from "vue";
import { trans } from "laravel-vue-i18n";

const props = defineProps({
	sectionKey: String,
	isExpanded: Boolean,
});

const emit = defineEmits(["toggleSection"]);

const formattedValue = ref("");
const selectedOption = ref(null);

const sectionTitles = {
	depositLimit: trans("Limite de Depósito"),
	betLimit: trans("Limite de Apostas"),
	lossLimit: trans("Limite de Perda"),
	timeLimit: trans("Limite de Tempo"),
};

const formatCurrency = (event) => {
	let value = event.target.value;
	value = value.replace(/[^\d,]/g, "");
	value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	if (value.includes(",")) {
		value = value.replace(/(\,\d{2})\d+/, "$1");
	}
	formattedValue.value = value;
};
</script>

<template>
    <section class="mb-6">
        <div class="flex items-center justify-between">
            <h2 class="text-sm font-semibold" style="color: var(--title-color)">
                {{ sectionTitles[sectionKey] }}
            </h2>
            <button class="toggle-button" @click="$emit('toggleSection', sectionKey)">
                <span v-if="isExpanded" class="svg-icon">
                    <svg height="1em" viewBox="0 0 448 512" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M246.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 205.3l137.4 137.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" fill="currentColor"></path>
                    </svg>
                </span>
                <span v-else class="svg-icon">
                    <svg height="1em" viewBox="0 0 448 512" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-12.5 0 45.3l160 160z" fill="currentColor"></path>
                    </svg>
                </span>
            </button>
        </div>

        <div v-if="!isExpanded" class="border-b mt-2"></div>

        <div v-if="isExpanded" class="mt-4">
            <p style="color: var(--text-color)">
                {{
                    sectionKey === "depositLimit"
                        ? $t("Controle o valor máximo que você pode depositar em sua conta durante o período selecionado.")
                        : sectionKey === "betLimit"
                            ? $t("Defina o valor máximo para gasto total em apostas durante o período selecionado.")
                            : sectionKey === "lossLimit"
                                ? $t("Restringe o valor máximo que você pode perder em suas apostas durante o período selecionado.")
                                : $t("Estabeleça quanto tempo você pode gastar apostando em um determinado período")
                }}
            </p>
            <div class="flex items-center pb-4 gap-4">
                <span>Límite estabelecido:</span>
                <label class="radio-label">
                    <input class="radio-input" type="radio" :name="`limit-${sectionKey}`" value="0" v-model="selectedOption" />
                    <span>{{$t('Não')}}</span>
                </label>
                <label class="radio-label">
                    <input class="radio-input" type="radio" :name="`limit-${sectionKey}`" value="1" v-model="selectedOption" />
                    <span>{{$t('Sim')}}</span>
                </label>
            </div>
            <div v-if="selectedOption === '1'" class="additional-fields mt-4">
                <div class="flex gap-4">
                    <label class="select-label">{{$t('Período')}}</label>
                    <select class="select">
                        <option value="0">{{$t('Selecione o período')}}</option>
                        <option value="1">{{$t('Diário')}}</option>
                        <option value="2">{{$t('Semanal')}}</option>
                        <option value="3">{{$t('Mensal')}}</option>
                        <option value="4">{{$t('Anual')}}</option>
                    </select>
                    <label class="currency-label">{{$t('Limite:')}}</label>
                    <input v-model="formattedValue" class="currency-input" placeholder="R$ 0,00" type="text" inputmode="numeric" @input="formatCurrency" />
                </div>
            </div>
            <button class="save-button mt-4">{{$t('Guardar')}}</button>
            <div class="border-b mt-4"></div>
        </div>
    </section>
</template>

<style scoped>
.select,
.currency-input {
    flex: 1;
    min-width: 120px;
    max-width: 100%;
    width: 100%;
    background-color: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: var(--text-color);
    box-sizing: border-box;
}

.flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.gap-4 {
    gap: 1rem;
}

.additional-fields {
    margin-top: 1rem;
}

.save-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--ci-primary-color);
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.save-button:hover {
    background-color: var(--background-color);
}

.radio-label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: var(--text-color);
    justify-content: flex-start;
    gap: 4px;
    margin-right: 8px;
}

.radio-input {
    accent-color: var(--primary-color);
    transform: scale(1.2);
}

.border-b {
    border-bottom: 1px solid var(--text-color);
}

.toggle-button {
    background: none;
    border: none;
    color: var(--sub-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    padding: 0;
}

.toggle-button:hover {
    color: var(--primary-color);
}

.border-b {
    border-bottom: 1px solid var(--text-color);
}

.pb-4 {
    padding-bottom: 1rem;
}

.mt-4 {
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .select,
    .currency-input {
        min-width: unset;
        width: calc(100% - 1rem);
    }
}
</style>

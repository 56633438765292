<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import { ref, onMounted, computed } from "vue";
import HttpApi from "@/Services/HttpApi.js";
import GameList from "@/Components/Cassino/GameList.vue";
import EmptyDataMessage from "@/Components/Cassino/EmptyDataMessage.vue";
import BannerCarousel from "@/Components/Home/BannerCarousel.vue";
import { useBannerStore } from "@/Stores/BannerStore.js";
import Footer from "@/Components/Cassino/AllGames/Footer.vue";
import Dropdown from "@/Components/Cassino/AllGames/Dropdown.vue";

const isLoading = ref(true);
const bannerStore = useBannerStore();
const games = ref({ data: [], total: 0 });
const searchTerm = ref("");
const selectedProviders = ref([]);
const selectedCategories = ref([]);
const errorMessage = ref("");
const page = ref(1);
const itemsPerPage = 12;

const banners = computed(() => bannerStore.banners);

const providers = ["Provider1", "Provider2", "Provider3"];
const categories = ["1", "2", "3"];

function debounce(func, delay) {
	let timeout;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
}

const fetchGames = async () => {
	isLoading.value = true;
	errorMessage.value = "";

	try {
		const response = await HttpApi.get(
			`/casinos/games?page=${page.value}&searchTerm=${
				searchTerm.value
			}&providers=${selectedProviders.value.join(
				",",
			)}&categories=${selectedCategories.value.join(",")}`,
		);
		const fetchedGames = response.data.games;

		if (page.value === 1) {
			games.value = fetchedGames;
			return;
		}

		games.value.data = [...games.value.data, ...fetchedGames.data];
		games.value.total = fetchedGames.total;
	} catch (error) {
		console.error(error);
		errorMessage.value = "Ocorreu um erro ao carregar os jogos.";
	} finally {
		isLoading.value = false;
	}
};

const fetchBanners = async () => {
	try {
		await bannerStore.fetchBanners();
	} catch (error) {
		console.error("Erro ao carregar banners", error);
	}
};

const searchGames = debounce(async () => {
	console.log("Valor de pesquisa:", searchTerm.value);
	searchTerm.value = searchTerm.value ? searchTerm.value.trim() : "";
	page.value = 1;
	await fetchGames();
}, 500);

const loadMoreGames = async () => {
	if (games.value.data.length < games.value.total) {
		page.value += 1;
		await fetchGames();
	}
};

onMounted(async () => {
	await Promise.all([fetchGames(), fetchBanners()]);
	isLoading.value = false;
});
</script>

<template>
    <BaseLayout>
        <div class="w-full text-center mx-auto">
            <div v-if="isLoading && games.data.length <= 10">
                <p class="mb-6 text-lg pt-24 text-[var(--text-color)]">
                    {{ $t("Loading information...") }}
                </p>
            </div>

            <div v-else class="px-24">
                <BannerCarousel :banners="banners" :hideExtras="true"/>

                <div class="flex justify-end items-center space-x-4 mb-4">
                    <input
                        v-model="searchTerm"
                        @input="searchGames"
                        class="search-input"
                        :placeholder="$t('Search games...')"
                    />
                    
                    <Dropdown title="Providers" :options="providers" v-model:selectedProviders="selectedProviders"
                              @searchGames="searchGames"/>
                    <Dropdown title="Categories" :options="categories" v-model:selectedCategories="selectedCategories"
                              @searchGames="searchGames"/>
                </div>

                <div>
                    <GameList
                        v-if="games && games.total > 0"
                        :games="games.data"
                        :key="games.data.length"
                    />
                </div>

                <div v-if="errorMessage" class="text-red-500 text-center">
                    {{ errorMessage }}
                </div>

                <EmptyDataMessage
                    v-else-if="!errorMessage && (!games || games.total === 0)"
                />

                <Footer :games="games" :isLoading="isLoading" @loadMoreGames="loadMoreGames"/>
            </div>
        </div>
    </BaseLayout>
</template>


<style scoped>
.search-input {
    background-color: var(--background-color);
    border-radius: 0.25rem;
    padding: 0.3rem 0.5rem;
    width: 20%;
}

.text-center span {
    color: var(--text-color);
}
</style>

<script setup>
import { computed, onMounted } from "vue";
import { initFlowbite } from "flowbite";
import NavTopComponent from "@/Components/Nav/NavTopComponent.vue";
import SideBarComponent from "@/Components/Nav/SideBarComponent.vue";
import FooterComponent from "@/Components/UI/FooterComponent.vue";
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import CookiesComponent from "@/Components/UI/CookiesComponent.vue";
import ReturnTop from "@/Components/UI/ReturnTop.vue";
import { useTextBannerStore } from "@/Stores/TextBannerStore.js";
import { useHighlightedButtonStore } from "@/Stores/HighlightedButtonStore.js";
import { useCategoryStore } from "@/Stores/CategoryStore.js";
import { useFooterLinkStore } from "@/Stores/FooterLinkStore.js";
import { useBottomNavigationLinkStore } from "@/Stores/BottomNavigationLinkStore.js";
import { useSocialLinksStore } from "@/Stores/SocialLinksStore.js";
import { sidebarStore } from "@/Stores/SideBarStore.js";

const sidebarMenuStore = sidebarStore();
const sidebarStatus = computed(() => sidebarMenuStore.sidebarStatus);

onMounted(async () => {
	initFlowbite();

	await Promise.all([
		useTextBannerStore().fetch(),
		useHighlightedButtonStore().fetch(),
		useCategoryStore().fetch(),
		useFooterLinkStore().fetch(),
		useBottomNavigationLinkStore().fetch(),
		useSocialLinksStore().fetch(),
	]);
});
</script>

<template>
    <ReturnTop />
    <NavTopComponent :simple="false" />
    <SideBarComponent v-once />
    <div :class="{
            'mt-[75px]': true,
            'sm:ml-64': sidebarStatus,
         }">
        <div class="relative">
            <slot></slot>
            <FooterComponent v-once />
            <BottomNavComponent v-once />
        </div>
    </div>
</template>

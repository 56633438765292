<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";

const isLoading = ref(false);
const typeInputPassword = ref("password");
const form = ref({
	email: "",
	password: "",
	password_confirmation: "",
	token: "",
});

const route = useRoute();
const router = useRouter();
const token = ref(null);

onMounted(() => {
	token.value = route.params.token;
	form.value.token = token.value;
});

const togglePassword = () => {
	typeInputPassword.value =
		typeInputPassword.value === "password" ? "text" : "password";
};

const resetPasswordSubmit = async () => {
	isLoading.value = true;
	const _toast = useToast();

	try {
		await HttpApi.post(`auth/reset-password/${token.value}`, form.value);
		_toast.success("Senha restaurada com sucesso!");
		router.push({ name: "login" });
	} catch (error) {
		Object.entries(JSON.parse(error.request.responseText)).forEach(
			([key, value]) => {
				_toast.error(`${value}`);
			},
		);
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
    <form @submit.prevent="resetPasswordSubmit" method="post" action="" class="">
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-envelope text-success-emphasis"></i>
            </div>
            <input type="email"
                   name="email"
                   v-model="form.email"
                   class="input-group"
                   :placeholder="$t('Enter email or phone')"
                   required
            >
        </div>
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-lock text-success-emphasis"></i>
            </div>
            <input :type="typeInputPassword"
                   name="password"
                   v-model="form.password"
                   class="input-group pr-[40px]"
                   :placeholder="$t('Type the password')"
                   required
            >
            <button type="button" @click.prevent="togglePassword" class="absolute inset-y-0 right-0 flex items-center pr-3.5 ">
                <i v-if="typeInputPassword === 'password'" class="fa-regular fa-eye"></i>
                <i v-if="typeInputPassword === 'text'" class="fa-sharp fa-regular fa-eye-slash"></i>
            </button>
        </div>
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-lock text-success-emphasis"></i>
            </div>
            <input :type="typeInputPassword"
                   name="password_confirmation"
                   v-model="form.password_confirmation"
                   class="input-group pr-[40px]"
                   :placeholder="$t('Confirm the Password')"
                   required
            >
            <button type="button" @click.prevent="togglePassword" class="absolute inset-y-0 right-0 flex items-center pr-3.5">
                <i v-if="typeInputPassword === 'password'" class="fa-regular fa-eye"></i>
                <i v-if="typeInputPassword === 'text'" class="fa-sharp fa-regular fa-eye-slash"></i>
            </button>
        </div>
        <div class="mt-5 w-full">
            <button type="submit" class="ui-button-blue rounded w-full mb-3">
                {{ $t('Submit') }}
            </button>
        </div>
    </form>
</template>

<script setup>
import { computed, ref } from "vue";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import LoadingSpinner from "@/Components/UI/LoadingSpinner.vue";
import { VueTelInput } from "vue-tel-input";
import { useBannerStore } from "@/Stores/BannerStore.js";
import SocialAuthButtons from "@/Components/Auth/SocialAuthButtons.vue";

const emit = defineEmits(["register-toggle", "login-toggle"]);

const isLoadingRegister = ref(false);
const showConfirmationModal = ref(false);
const showReferenceCodeInput = ref(false);

const bannerStore = useBannerStore();
const banner = computed(() => bannerStore.registerBanner);

const registerForm = ref({
	name: "",
	email: "",
	password: "",
	password_confirmation: "",
	reference_code: "",
	term_a: false,
	agreement: false,
	phone: "",
});

const showPassword = ref(false);

const authStore = useAuthStore();
const _toast = useToast();

const modalClass = computed(() => {
	return window.innerWidth <= 768 ? "items-center justify-center d-flex" : "";
});

const handleClickOutside = (event) => {
	const registerModal = document.getElementById("outside");
	if (event.target === registerModal) {
		showConfirmationModal.value = true;
	}
};

const closeRegistration = () => {
	showConfirmationModal.value = false;
	emit("register-toggle");
};

const loginToggle = () => {
	emit("register-toggle");
	emit("login-toggle");
};

const continueRegistration = () => {
	showConfirmationModal.value = false;
};

const registerSubmit = async () => {
	isLoadingRegister.value = true;
	try {
		const response = await HttpApi.post("auth/register", registerForm.value);
		if (response.data.access_token !== undefined) {
			authStore.setToken(response.data.access_token);
			authStore.setUser(response.data.user);
			authStore.setIsAuth(true);
			registerForm.value = {
				name: "",
				email: "",
				password: "",
				password_confirmation: "",
				reference_code: "",
				term_a: false,
				agreement: false,
				phone: "",
			};
			emit("register-toggle");
			_toast.success("Su cuenta ha sido creada exitosamente");
		}
	} catch (error) {
		if (error.request && error.request.responseText) {
			const errorData = JSON.parse(error.request.responseText);
			console.error(errorData);
			if (errorData) {
				errorData.errors
					? Object.values(errorData.errors).forEach((value) => {
							_toast.error(`${value}`);
						})
					: Object.entries(errorData).forEach(([key, value]) => {
							_toast.error(`${value}`);
						});
			} else {
				_toast.error("An unknown error occurred.");
			}
		} else {
			_toast.error("Network error. Please check your connection.");
		}
	} finally {
		isLoadingRegister.value = false;
	}
};
</script>

<template>
    <div id="modalElRegister" tabindex="-1" aria-hidden="true"
        class="fixed inset-0 z-[101] flex hidden items-center justify-center overflow-y-auto md:bg-black md:bg-opacity-20"
        @click="handleClickOutside" v-show="!showConfirmationModal">
        <div id="outside" class="min-h-screen w-full flex items-center justify-center md:px-4 md:py-6">
            <div class="relative w-full h-screen md:h-auto md:max-w-lg bg-base md:rounded-lg md:shadow-lg mx-auto md:mt-20"
                @click.stop>
                <div class="relative">
                    <img :src="`/storage` + banner.image" alt="Banner" class="w-full h-40 object-cover rounded-t-lg" />
                    <button @click="showConfirmationModal = true" class="absolute top-2 right-2 text-white text-2xl">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="p-5 max-h-[calc(100dvh-10rem)] md:max-h-[calc(100dvh-4rem)] overflow-y-auto hide-scrollbar">
                    <form @submit.prevent="registerSubmit" method="post" action="">
                        <div class="relative mb-3">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <i class="fa-regular fa-user text-success-emphasis"></i>
                            </div>
                            <input type="text" name="name" v-model="registerForm.name" class="input-group"
                                placeholder="Ingrese su nombre" required />
                        </div>
                        <div class="relative mb-3">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <i class="fa-regular fa-envelope text-success-emphasis"></i>
                            </div>
                            <input type="email" name="email" v-model="registerForm.email" class="input-group"
                                placeholder="Ingrese su correo electrónico" required />
                        </div>
                        <div class="relative mb-3">
                            <VueTelInput name="phone" style="border:0 !important;" v-model="registerForm.phone"
                                placeholder="Ingrese su número de teléfono" />
                        </div>
                        <div class="relative mb-3">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <i class="fa-regular fa-lock text-success-emphasis"></i>
                            </div>
                            <input :type="showPassword ? 'text' : 'password'" name="password"
                                v-model="registerForm.password" class="input-group pr-[40px]"
                                placeholder="Ingrese la contraseña" required />
                            <div class="absolute inset-y-0 right-0 flex items-center pr-3.5">
                                <button type="button" @click="showPassword = !showPassword" class="focus:outline-none">
                                    <i :class="showPassword ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"></i>
                                </button>
                            </div>
                        </div>
                        <div class="relative mb-3">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                <i class="fa-regular fa-lock text-success-emphasis"></i>
                            </div>
                            <input :type="showPassword ? 'text' : 'password'" name="password_confirmation"
                                v-model="registerForm.password_confirmation" class="input-group pr-[40px]"
                                placeholder="Confirme la contraseña" required />
                            <div class="absolute inset-y-0 right-0 flex items-center pr-3.5">
                                <button type="button" @click="showPassword = !showPassword" class="focus:outline-none">
                                    <i :class="showPassword ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"></i>
                                </button>
                            </div>
                        </div>

                        <div class="relative mb-3">
                            <template v-if="showReferenceCodeInput">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                    <i class="fas fa-user-plus text-success-emphasis"></i>
                                </div>
                                <input type="text" name="reference_code" v-model="registerForm.reference_code"
                                    class="input-group" placeholder="Ingrese su código de referencia" required />
                                <button type="button" @click="showReferenceCodeInput = false"
                                    class="absolute right-3 top-2 text-gray-500 hover:text-gray-700">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </template>
                            <template v-else>
                                <p class="secondary-text cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 text-center"
                                   @click="showReferenceCodeInput = true">
                                    Código de referencia
                                </p>
                            </template>
                        </div>

                        <p class="text-center text-sm mb-3">
                            Al registrarte aceptas los
                            <a href="#"
                               class="text-center text-sm mb-3 secondary-text cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">términos
                                y condiciones</a>
                        </p>
                        <div class="mt-5 w-full">
                            <button type="submit" class="ui-button-blue rounded w-full mb-3"
                                :disabled="isLoadingRegister">
                                <span v-if="isLoadingRegister">
                                    <LoadingSpinner /> Registrando...
                                </span>

                                <span v-else>
                                    Registrar
                                </span>
                            </button>
                        </div>
                    </form>
                    <div class="flex items-center justify-center mb-6">
                        <hr class="w-1/4 border-gray-300" />
                        <span class="px-2 text-[rgb(225,228,231,0.73)] whitespace-nowrap">Regístrate desde tu
                            cuenta:</span>
                        <hr class="w-1/4 border-gray-300" />
                    </div>

                    <SocialAuthButtons />

                    <p class="secondary-text cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
                        <button class="flex justify-center" @click="loginToggle">
                            ¿Ya tienes una cuenta? Iniciar sesión
                        </button>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div id="confirmationModal" v-if="showConfirmationModal"
        class="fixed inset-0 z-[102] flex items-center justify-center overflow-y-auto md:bg-black md:bg-opacity-20 md:flex md:items-baseline"
        @click="closeRegistration">
        <div class="relative w-full h-full md:h-auto md:max-w-md bg-base md:rounded-lg md:shadow-lg mx-auto md:mt-80 p-6"
            @click.stop :class="modalClass">
            <div class="text-center">
                <h3 class="text-2xl font-light mb-4">
                    ¿Estás seguro de que deseas cancelar tu registro?
                </h3>
                <p class="text-sm text-[rgb(140,142,147)] mb-6">
                    Realice su primer depósito y gane hasta 80 giros en el Fortune Tiger
                </p>
                <button @click="continueRegistration" class="ui-button-blue rounded w-full mb-3">
                    Continuar
                </button>
                <p
                    @click="closeRegistration"
                    class="secondary-text cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                >
                    Sí, quiero cancelar
                </p>
            </div>
        </div>
    </div>
</template>

<style>
.vue-tel-input .vti__input {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: #191a1e !important;
    color: #ffffff !important;
}

.vue-tel-input .vti__dropdown {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-color: #191a1e !important;
}

.vue-tel-input .vti__dropdown .vti__list {
    background-color: #191a1e !important;
    color: #ffffff !important;
}

.vue-tel-input .vti__dropdown .vti__list .vti__item {
    color: #ffffff !important;
}

.vti__dropdown-list {
    background-color: #191a1e !important;
}

.vti__dropdown-item--highlighted {
    background-color: var(--ci-primary-color) !important;
}

.vti__dropdown-item.highlighted {
    background-color: var(--ci-primary-color) !important;
}

.vue-tel-input:focus-within {
    border-color: var(--ci-primary-color);
    box-shadow: 0 0 0 1px var(--ci-primary-color);
}

.vue-tel-input:hover {
    border-color: var(--ci-primary-color);
    box-shadow: 0 0 0 1px var(--ci-primary-color);
}

body.modal-open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {

    #modalElRegister,
    #confirmationModal {
        background: none;
    }

    .bg-base {
        border-radius: 0;
    }

    .overflow-y-auto {
        -webkit-overflow-scrolling: touch;
    }
}

@supports (-webkit-touch-callout: none) {
    .overflow-y-auto {
        -webkit-overflow-scrolling: touch;
    }
}

.d-flex {
    display: flex;
}

.hide-scrollbar {
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none !important;;  /* Chrome, Safari, and Opera */
}
</style>

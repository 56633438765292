<script setup>
import { ref, onMounted } from "vue";
import BaseProfileLayout from "@/Layouts/BaseProfileLayout.vue";
import { useMenuBarStore } from "@/Stores/MenuBarStore.js";
import Tabs from "@/Components/Profile/TransactionHistory/Tabs.vue";
import DateFilters from "@/Components/Profile/DateFilters.vue";

const activeTab = ref(0);

const activePeriod = (period) => {
	console.log(period);
};

onMounted(() => {
	useMenuBarStore().setActiveMenu("wallet");
});
</script>

<template>
    <BaseProfileLayout>
        <div class="profile-section-container">
                <span class="font-bold text-sm">
                    {{ $t('Histórico de Transações').toUpperCase() }}:
                </span>
        </div>


        <div class="profile-section-container mt-4">
            <Tabs v-model="activeTab" />

            <div class="mt-4">
                <DateFilters @selectFilter="activePeriod" />

                <div class="alert-warning text-sm bg-gray-800 text-center p-4 rounded-lg mt-4">
                    {{ $t('Você ainda não realizou nenhuma transação!') }}
                </div>
            </div>
        </div>
    </BaseProfileLayout>
</template>


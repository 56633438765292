import { defineStore } from "pinia";
import HttpApi from "@/Services/HttpApi.js";

export const useShortcutStore = defineStore("shortcut", {
	state() {
		return {
			shortcuts: [
				{
					id: 0,
					text: "",
					subtext: "",
					url: "",
					icon: "",
					background_color: "",
					text_color: "",
				},
			],
		};
	},

	actions: {
		async fetchShortcuts() {
			try {
				const response = await HttpApi.get("/layout/shortcuts");
				this.shortcuts = response.data;
			} catch (error) {}
		},
	},

	getters: {},
});

<template>
    <div class="w-full rounded shadow">
        <ul>
            <li class="w-full mb-2">
                <RouterLink :to="{ name: 'profileWallet' }" active-class="wallet-active"
                            class="flex justify-between items-center w-full bg-custom hover-bg-custom dark-bg-custom px-4 py-3 rounded hover-dark-bg-custom transition duration-700">
                    <div class="flex items-center">
                        <span class="w-8"><i class="fa-solid fa-user"></i></span>
                        <span>{{ $t('My profile') }}</span>
                    </div>
                    <span><i class="fa-solid fa-chevron-right"></i></span>
                </RouterLink>
            </li>

            <li class="w-full opacity-custom">
                <div @click="logoutAccount"
                    class="flex justify-between items-center w-full bg-custom hover-bg-custom dark-bg-custom px-4 py-3 rounded hover-dark-bg-custom transition duration-700 cursor-pointer">
                    <div class="flex items-center">
                        <span class="w-8"><i class="fa-solid fa-right-from-bracket"></i></span>
                        <span>{{ $t('To Exit') }}</span>
                    </div>
                    <span><i class="fa-solid fa-chevron-right"></i></span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useAuthStore } from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";

const authStore = useAuthStore();
const router = useRouter();
const toast = useToast();

const logoutAccount = async () => {
	try {
		await HttpApi.post("auth/logout", {});
		authStore.logout();
		await router.push({ name: "home" });
		toast.success("Has cerrado sesión con éxito");
		setTimeout(() => {
			window.location.reload();
		}, 700);
	} catch (error) {
		if (error.response && error.response.data) {
			const errors = error.response.data;
			for (const [key, value] of Object.entries(errors)) {
				toast.error(`${value}`);
			}
		} else {
			toast.error("Ocurrió un error al intentar cerrar sesión.");
		}
	}
};
</script>

<style scoped>
.bg-custom {
    background-color: var(--bg-color);
}

.hover-bg-custom:hover {
    background-color: var(--hover-bg-color);
}

.dark-bg-custom {
    background-color: var(--dark-bg-color);
}

.hover-dark-bg-custom:hover {
    background-color: var(--hover-dark-bg-color);
}

.bg-red-custom {
    background-color: var(--red-bg-color);
}

.bg-green-custom {
    background-color: var(--green-bg-color);
}

.opacity-custom {
    opacity: var(--text-opacity);
}

.bg-custom,
.hover-bg-custom:hover,
.dark-bg-custom,
.hover-dark-bg-custom:hover,
.bg-red-custom,
.bg-green-custom,
.opacity-custom {
    transition: background-color 0.3s ease, opacity 0.3s ease;
}
</style>
